import React from 'react'
import { Link } from 'react-router-dom'

const ProjectItem = ({ project }) => {
  return (
    <div className='project__item'>
      <p className='project__item__title'>
        {project && project.title}{' '}
        <span>
          ({project && project.startYear} - {project && project.endYear})
        </span>
      </p>

      {project && project.link ? (
        <a href={project && project.link}>
          Learn More{' '}
          <span>
            <i className='fas fa-chevron-right'></i>
          </span>
        </a>
      ) : (
        <Link
          to={`/project/${project && project._id}`}
          className='project-link'
        >
          Learn More{' '}
          <span>
            <i className='fas fa-chevron-right'></i>
          </span>
        </Link>
      )}
    </div>
  )
}

export default ProjectItem

import React, { useEffect, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Fade } from 'react-reveal'
import { listProjects } from '../../actions/projectActions'
import { listPublications } from '../../actions/publicationActions'
import PageHeader from '../layout/PageHeader'
import Footer from '../layout/Footer'
import Search from '../research/Search'
import ProjectsAccordion from '../research/ProjectsAccordion'
import Publications from '../research/Publications'

const Research = () => {
  const dispatch = useDispatch()

  const projectList = useSelector((state) => state.projectList)
  const { loading: loadingProjects, projects } = projectList

  const projectFilter = useSelector((state) => state.projectFilter)
  const { loading: loadingFilter, filteredProjects } = projectFilter

  const publicationList = useSelector((state) => state.publicationList)
  const { loading: loadingPublications, publications } = publicationList

  const publicationFilter = useSelector((state) => state.publicationFilter)
  const { filteredPublications } = publicationFilter

  useEffect(() => {
    dispatch(listProjects())
    dispatch(listPublications())
  }, [dispatch])

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div id='research'>
      {/* Header */}
      <PageHeader header='header header__research'>
        <Fade left>
          <h2 className='page__header__title'>What We Do</h2>
        </Fade>
      </PageHeader>

      {/* Search */}
      <Search />

      {/* Projects */}
      <div id='research__projects'>
        <h2 className='section__title'>Projects</h2>

        <ProjectsAccordion
          projects={projects}
          loading={loadingProjects}
          filteredProjects={filteredProjects}
          loadingFilter={loadingFilter}
        />
      </div>

      {/* Publications */}
      <div id='research__publications'>
        <h2 className='section__title'>Publications</h2>

        <Publications
          publications={publications}
          filteredPublications={filteredPublications}
          loading={loadingPublications}
        />
      </div>

      {/* Footer */}
      <Footer />
    </div>
  )
}

export default Research

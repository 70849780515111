import React, { useState, useLayoutEffect } from 'react'
import { Fade } from 'react-reveal'
import { Link } from 'react-router-dom'

import McGillLogo from './assets/logos/university.png'
import Logo from './assets/logos/logo.png'

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false)

  useLayoutEffect(() => {
    setIsOpen(false)
  }, [])

  const handleToggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <nav id='navbar' className='navbar'>
      <div className='nav-center'>
        <div className='nav-header'>
          <Fade left>
            <div>
              <a href='https://www.mcgill.ca/' target='_blank'>
                <img src={McGillLogo} alt='McGill University' id='nav-mcgill' />
              </a>
              <a href='/'>
                <img
                  src={Logo}
                  alt='The Leadership & Learning For Sustainability Lab'
                  id='nav-logo'
                />
              </a>
            </div>
          </Fade>

          <button type='button' className='nav-btn' onClick={handleToggle}>
            <i className='fas fa-align-right nav-icon'></i>
          </button>
        </div>
        <ul className={isOpen ? 'nav-links show-nav' : 'nav-links'}>
          <Fade bottom>
            <li id='nav-item'>
              <Link to='/' className='nav-item'>
                Home
              </Link>
            </li>
          </Fade>
          <Fade bottom>
            <li id='nav-item'>
              <Link to='/members' className='nav-item'>
                Who We Are
              </Link>
            </li>
          </Fade>

          <Fade bottom>
            <li id='nav-item'>
              <Link to='/research' className='nav-item'>
                What We Do
              </Link>
            </li>
          </Fade>

          <Fade bottom>
            <li id='nav-item'>
              <Link to='/latest' className='nav-item'>
                News & Events
              </Link>
            </li>
          </Fade>
        </ul>
      </div>
    </nav>
  )
}

export default Navbar

// import React from 'react'
// import { Link } from 'react-router-dom'
// import { Fade } from 'react-reveal'
// import McGillLogo from './assets/logos/university.png'
// import Logo from './assets/logos/logo.png'

// const Navbar = () => {
//   return (
//     <div className='navbar'>
//       <Fade left>
//         <div className='navbar__logo'>
//           {/* University Logo */}
//           <div className='university__logo'>
//             <a href='https://www.mcgill.ca/' target='_blank'>
//               <img src={McGillLogo} alt='McGill University' />
//             </a>
//           </div>

//           {/* Lab Logo */}
//           <div className='lab__logo'>
//             <a href='/'>
//               <img
//                 src={Logo}
//                 alt='The Leadership & Learning For Sustainability Lab'
//               />
//             </a>
//           </div>
//         </div>
//       </Fade>

//       <ul className='navbar__list'>
//         <Fade bottom>
//           <Link to='/' className='navbar__list__item'>
//             <li>Home</li>
//           </Link>
//         </Fade>

//         <Fade bottom>
//           <Link to='/members' className='navbar__list__item'>
//             <li>Who We Are</li>
//           </Link>
//         </Fade>

//         <Fade bottom>
//           <Link to='/research' className='navbar__list__item'>
//             <li>What We Do</li>
//           </Link>
//         </Fade>

//         <Fade bottom>
//           <Link to='/latest' className='navbar__list__item'>
//             <li>News & Events</li>
//           </Link>
//         </Fade>
//       </ul>
//     </div>
//   )
// }

// export default Navbar

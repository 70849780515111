import React, { useMemo, useState } from 'react'
import Loader from '../layout/Loader'
import Message from '../layout/Message'
import BlogItem from './BlogItem'
import Pagination from '../layout/Pagination'

let PageSize = 6

const BlogCards = ({ loading, error, news }) => {
  const [currentPage, setCurrentPage] = useState(1)

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize
    const lastPageIndex = firstPageIndex + PageSize
    return news && news.slice(firstPageIndex, lastPageIndex)
  }, [currentPage, news])

  return (
    <div className='blogs__container'>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        currentTableData &&
        currentTableData.map((newsItem, id) => (
          <BlogItem key={id} blogItem={newsItem} />
        ))
      )}
      <Pagination
        className='pagination-bar'
        currentPage={currentPage}
        totalCount={news && news.length}
        pageSize={PageSize}
        onPageChange={(page) => setCurrentPage(page)}
      />
    </div>
  )
}

export default BlogCards

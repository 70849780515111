import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { listNews } from '../../actions/newsActions'
import CardItem from './CardItem'

const Cards = () => {
  const dispatch = useDispatch()

  const newsList = useSelector((state) => state.newsList)
  const { loading, error, news } = newsList

  useEffect(() => {
    dispatch(listNews())
  }, [dispatch])

  // let index = news && news.length

  const firstItem = news && news[0]
  const secondItem = news && news[1]
  const thirdItem = news && news[2]
  const fourthItem = news && news[3]
  const fifthItem = news && news[4]

  return (
    <>
      {/* Cards First Row */}
      <div className='cards__first__row'>
        <CardItem newsItem={firstItem} className='lg__card' />
        <CardItem newsItem={secondItem} className='lg__card' />
      </div>
      {/* Cards Second Row */}
      <div className='cards__second__row'>
        <CardItem newsItem={thirdItem} className='xs__card' />
        <CardItem newsItem={fourthItem} className='xs__card' />
        <CardItem newsItem={fifthItem} className='xs__card' />
      </div>
    </>
  )
}

export default Cards

import React from 'react'
import Loader from '../layout/Loader'
import PublicationItem from './PublicationItem'

const PeerReviewed = ({ publications, filteredPublications, loading }) => {
  const peer =
    publications &&
    publications.filter((pub) => {
      if (pub.type === 'Peer Reviewed Journal Article') return pub
    })

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          {filteredPublications ? (
            filteredPublications.map((publication, idx) => {
              if (publication.type === 'Peer Reviewed Journal Article') {
                return <PublicationItem publication={publication} />
              } else {
                return null
              }
            })
          ) : (
            <>
              {peer &&
                peer.map((publication, idx) => (
                  <PublicationItem publication={publication} />
                ))}
            </>
          )}
        </div>
      )}
    </>
  )
}

export default PeerReviewed

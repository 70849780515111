import React, { Fragment, useState } from 'react'
import Fade from 'react-reveal/Fade'

import Modal from '../layout/Modal'
import avatar from './avatar.png'

const MemberItem = ({ member }) => {
  const { name, title, bio, image } = member
  const [status, setStatus] = useState(false)

  return (
    <Fragment>
      {status && (
        <Modal closeModal={() => setStatus(false)}>
          <div className='modal-content'>
            <div className='modal-content-left'>
              <img src={image ? image : avatar} alt='Member Image' />
              <h3>{name}</h3>
              <h5>{title}</h5>
            </div>
            <div className='modal-content-right'>
              <h5>About</h5>

              <div
                dangerouslySetInnerHTML={{ __html: bio }}
                className='reg__paragraph member__bio'
              />
            </div>
          </div>
        </Modal>
      )}
      <Fade bottom>
        <div className='members__item' onClick={() => setStatus(true)}>
          <div className='members__item__image'>
            <img src={image ? image : avatar} alt='Lab Member' />
          </div>
          <div className='members__item__info'>
            <h5 className='member__name'>{name}</h5>
            <h5 className='member__title'>{title}</h5>
          </div>
        </div>
      </Fade>
    </Fragment>
  )
}

export default MemberItem

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { logout } from '../../actions/userActions'

const AdminPageContainer = ({ children }) => {
  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const handleLogout = (e) => {
    e.preventDefault()

    dispatch(logout())
  }
  return (
    <div id='admin-container'>
      <div id='admin-container-navigator'>
        {userInfo && (
          <Link
            to='/admin/users'
            className='admin-nav-item'
            onClick={handleLogout}
            style={{
              backgroundColor: '#fff',
              color: '#14213d',
              border: '3px solid #14213d',
            }}
          >
            <i className='fas fa-sign-out-alt admin-nav-icon'></i>
            Logout
          </Link>
        )}
        <Link to='/admin/users' className='admin-nav-item'>
          <i className='fas fa-user-tie admin-nav-icon'></i>
          Dr. Blane Harvey
        </Link>
        <Link to='/admin/members' className='admin-nav-item'>
          <i className='fas fa-users admin-nav-icon'></i>
          Members
        </Link>
        <Link to='/admin/messages' className='admin-nav-item'>
          <i className='fas fa-envelope admin-nav-icon'></i>
          Messages
        </Link>
        <Link to='/admin/news' className='admin-nav-item'>
          <i className='fas fa-newspaper admin-nav-icon'></i>
          News
        </Link>
        <Link to='/admin/projects' className='admin-nav-item'>
          <i className='fas fa-leaf admin-nav-icon'></i>
          Projects
        </Link>
        <Link to='/admin/publications' className='admin-nav-item'>
          <i className='fas fa-book-open admin-nav-icon'></i>
          Publications
        </Link>
      </div>
      <div id='admin-container-child'>{children}</div>
    </div>
  )
}

export default AdminPageContainer

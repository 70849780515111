import React from 'react'
import { Fade } from 'react-reveal'
import avatar from './avatar.png'

const DrHarvey = ({ user }) => {
  return (
    <div id='members__top'>
      <h2 className='section__title'>Meet The Team</h2>
      <div className='user'>
        <div className='user__left'>
          <div className='user__image'>
            <img
              src={user && user.image ? user.image : avatar}
              alt='Dr. Blane Harvey'
            />
          </div>
          <h5>Dr. Blane Leslie Harvey</h5>
          <ul className='user__titles'>
            {user &&
              user.titles &&
              user.titles.map((title, id) => (
                <li key={id}>{title.titleName}</li>
              ))}
          </ul>
        </div>
        <div className='user__right'>
          <h5>About</h5>
          <div
            className='reg__paragraph'
            dangerouslySetInnerHTML={{ __html: user && user.bio }}
          />
        </div>
      </div>
    </div>
  )
}

export default DrHarvey

import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../layout/Message'
import Loader from '../layout/Loader'
import NewsNavItem from './NewsNavItem'
import { listNews } from '../../actions/newsActions'

const NewsHiddenNav = ({ active }) => {
  const dispatch = useDispatch()

  const newsList = useSelector((state) => state.newsList)
  const { loading, error, news } = newsList

  const newsShown = news && news.slice(0, 5)

  useEffect(() => {
    dispatch(listNews())
  }, [dispatch])

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <div id='news-nav'>
          {newsShown &&
            newsShown.map((newsItem) => (
              <NewsNavItem
                key={newsItem._id}
                newsItem={newsItem}
                active={active}
              />
            ))}
        </div>
      )}
    </Fragment>
  )
}

export default NewsHiddenNav

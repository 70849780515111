export const PUBLICATION_LIST_REQUEST = 'PUBLICATION_LIST_REQUEST'
export const PUBLICATION_LIST_SUCCESS = 'PUBLICATION_LIST_SUCCESS'
export const PUBLICATION_LIST_FAIL = 'PUBLICATION_LIST_FAIL'

export const PUBLICATION_DETAILS_REQUEST = 'PUBLICATION_DETAILS_REQUEST'
export const PUBLICATION_DETAILS_SUCCESS = 'PUBLICATION_DETAILS_SUCCESS'
export const PUBLICATION_DETAILS_FAIL = 'PUBLICATION_DETAILS_FAIL'

export const PUBLICATION_CREATE_REQUEST = 'PUBLICATION_CREATE_REQUEST'
export const PUBLICATION_CREATE_SUCCESS = 'PUBLICATION_CREATE_SUCCESS'
export const PUBLICATION_CREATE_FAIL = 'PUBLICATION_CREATE_FAIL'
export const PUBLICATION_CREATE_RESET = 'PUBLICATION_CREATE_RESET'

export const PUBLICATION_UPDATE_REQUEST = 'PUBLICATION_UPDATE_REQUEST'
export const PUBLICATION_UPDATE_SUCCESS = 'PUBLICATION_UPDATE_SUCCESS'
export const PUBLICATION_UPDATE_FAIL = 'PUBLICATION_UPDATE_FAIL'
export const PUBLICATION_UPDATE_RESET = 'PUBLICATION_UPDATE_RESET'

export const PUBLICATION_DELETE_REQUEST = 'PUBLICATION_DELETE_REQUEST'
export const PUBLICATION_DELETE_SUCCESS = 'PUBLICATION_DELETE_SUCCESS'
export const PUBLICATION_DELETE_FAIL = 'PUBLICATION_DELETE_FAIL'

export const PUBLICATION_FILTER_REQUEST = 'PUBLICATION_FILTER_REQUEST'
export const PUBLICATION_FILTER_SUCCESS = 'PUBLICATION_FILTER_SUCCESS'
export const PUBLICATION_FILTER_FAIL = 'PUBLICATION_FILTER_FAIL'
export const PUBLICATION_FILTER_RESET = 'PUBLICATION_FILTER_RESET'

export const PUBLICATION_CLEAR_FILTER_REQUEST = 'PUBLICATION_FILTER_REQUEST'
export const PUBLICATION_CLEAR_FILTER_SUCCESS = 'PUBLICATION_FILTER_SUCCESS'
export const PUBLICATION_CLEAR_FILTER_FAIL = 'PUBLICATION_FILTER_FAIL'

export const PUBLICATION_FILE_DOWNLOAD_REQUEST =
  'PUBLICATION_FILE_DOWNLOAD_REQUEST'
export const PUBLICATION_FILE_DOWNLOAD_SUCCESS =
  'PUBLICATION_FILE_DOWNLOAD_SUCCESS'
export const PUBLICATION_FILE_DOWNLOAD_FAIL = 'PUBLICATION_FILE_DOWNLOAD_FAIL'

import React from 'react'
import Loader from '../layout/Loader'
import PublicationItem from './PublicationItem'

const SpecialIssues = ({ publications, filteredPublications, loading }) => {
  const edited =
    publications &&
    publications.filter((pub) => {
      if (pub.type === 'Edited Journal Special Issue') return pub
    })

  console.log(edited)

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          {filteredPublications ? (
            filteredPublications.map((publication, idx) => {
              if (publication.type === 'Edited Journal Special Issue') {
                return <PublicationItem key={idx} publication={publication} />
              } else {
                return null
              }
            })
          ) : (
            <>
              {edited &&
                edited.map((publication, idx) => (
                  <PublicationItem key={idx} publication={publication} />
                ))}
            </>
          )}
        </div>
      )}
    </>
  )
}

export default SpecialIssues

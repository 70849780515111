import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import Navbar from './components/layout/Navbar'
import Home from './components/screens/Home'
import Members from './components/screens/Members'
import Research from './components/screens/Research'
import Project from './components/screens/Project'
import Blogs from './components/screens/Blogs'
import BlogPost from './components/screens/BlogPost'

import Dashboard from './screens/Dashboard'

import UserLogin from './screens/UserLogin'
import UserList from './screens/UserList'
import UserEdit from './screens/UserEdit'

import MembersList from './screens/MembersList'
import MemberCreate from './screens/MemberCreate'
import MemberEdit from './screens/MemberEdit'

import MessagesList from './screens/MessagesList'
import MessageView from './screens/MessageView'

import NewsList from './screens/NewsList'
import NewsCreate from './screens/NewsCreate'
import NewsEdit from './screens/NewsEdit'

import ProjectsList from './screens/ProjectsList'
import ProjectCreate from './screens/ProjectCreate'
import ProjectEdit from './screens/ProjectEdit'

import PublicationsList from './screens/PublicationsList'
import PublicationCreate from './screens/PublicationCreate'
import PublicationEdit from './screens/PublicationEdit'

import PrivateRoute from './components/routing/PrivateRoute'

const App = () => {
  return (
    <Router>
      <Navbar />
      <Switch>
        {/* <div className='container'> */}
        <Route exact path='/' component={Home} />
        <Route exact path='/members' component={Members} />
        <Route exact path='/research' component={Research} />
        <Route exact path='/project/:id' component={Project} />
        <Route exact path='/latest' component={Blogs} />
        <Route exact path='/latest/:id' component={BlogPost} />
        <Route exact path='/admin' component={UserLogin} />
        <PrivateRoute exact path='/admin/dashboard' component={Dashboard} />
        <PrivateRoute exact path='/admin/users' component={UserList} />
        <PrivateRoute exact path='/admin/user/:id/edit' component={UserEdit} />

        <PrivateRoute exact path='/admin/members' component={MembersList} />
        <PrivateRoute
          exact
          path='/admin/member/:id/edit'
          component={MemberEdit}
        />
        <PrivateRoute
          exact
          path='/admin/member/create'
          component={MemberCreate}
        />

        <PrivateRoute exact path='/admin/messages' component={MessagesList} />
        <PrivateRoute
          exact
          path='/admin/messages/:id'
          component={MessageView}
        />

        <PrivateRoute exact path='/admin/news' component={NewsList} />

        <PrivateRoute
          exact
          path='/admin/newsitem/:id/edit'
          component={NewsEdit}
        />
        <PrivateRoute
          exact
          path='/admin/newsitem/create'
          component={NewsCreate}
        />

        <PrivateRoute exact path='/admin/projects' component={ProjectsList} />

        <PrivateRoute
          exact
          path='/admin/project/:id/edit'
          component={ProjectEdit}
        />
        <PrivateRoute
          exact
          path='/admin/project/create'
          component={ProjectCreate}
        />

        <PrivateRoute
          exact
          path='/admin/publications'
          component={PublicationsList}
        />

        <PrivateRoute
          exact
          path='/admin/publication/:id/edit'
          component={PublicationEdit}
        />
        <PrivateRoute
          exact
          path='/admin/publication/create'
          component={PublicationCreate}
        />
        {/* </div> */}
      </Switch>
    </Router>
  )
}

export default App

import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../layout/Loader'
import { postMessage } from '../../actions/messageActions'
import { MESSAGE_CREATE_RESET } from '../../constants/messageConstants'

const ContactForm = () => {
  const dispatch = useDispatch()

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [subject, setSubject] = useState('')
  const [body, setBody] = useState('')
  const [successMsg, setSuccessMsg] = useState('')
  const [errorMsg, setErrorMsg] = useState('')

  const messageCreate = useSelector((state) => state.messageCreate)
  const { loading, success } = messageCreate

  useEffect(() => {
    if (success) {
      dispatch({ type: MESSAGE_CREATE_RESET })
      setTimeout(() => {
        setSuccessMsg('')
      }, 5000)
      setSuccessMsg('Message sent successfully')
    }
  }, [dispatch, success])

  const submitHandler = (e) => {
    e.preventDefault()
    if (name === '' || email === '' || subject === '' || body === '') {
      setTimeout(() => {
        setErrorMsg('')
      }, 5000)
      setErrorMsg('All fields are required!')
    } else {
      dispatch(
        postMessage({
          name,
          email,
          subject,
          body,
        })
      )
      setName('')
      setEmail('')
      setSubject('')
      setBody('')
    }
  }

  return (
    <form onSubmit={submitHandler} className='contact__form'>
      <div className='form__flex__box'>
        {/* Name */}
        <div className='form__group'>
          <label htmlFor='name' className='form__label'>
            Name <span className='asterisc'>*</span>
          </label>
          <input
            type='text'
            value={name}
            onChange={(e) => setName(e.target.value)}
            className='form__input'
          ></input>
        </div>

        {/* Email */}
        <div className='form__group'>
          <label htmlFor='email' className='form__label'>
            Email <span className='asterisc'>*</span>
          </label>
          <input
            type='text'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className='form__input'
          ></input>
        </div>
      </div>

      {/* Subject */}
      <div className='form__group'>
        <label htmlFor='subject' className='form__label'>
          Subject <span className='asterisc'>*</span>
        </label>
        <input
          type='text'
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          className='form__input'
        ></input>
      </div>

      {/* Message Body */}
      <div className='form__group'>
        <label htmlFor='body' className='form__label'>
          Your Message <span className='asterisc'>*</span>
        </label>
        <textarea
          type='text'
          value={body}
          onChange={(e) => setBody(e.target.value)}
          className='form__input textarea'
        ></textarea>
      </div>

      {/* Submit Button */}
      {loading && <Loader />}
      <button
        type='submit'
        className={`submit__btn ${
          errorMsg ? 'submit__error' : successMsg ? 'submit__success' : null
        }`}
      >
        {errorMsg ? errorMsg : successMsg ? successMsg : 'Send'}
      </button>
    </form>
  )
}

export default ContactForm

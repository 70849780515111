import React, { Fragment, useState, useEffect, useRef } from 'react'
import jwt from 'jwt-decode'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Button, Row, Col } from 'react-bootstrap'
import Dropzone from 'react-dropzone'
import QuillUpdate from '../components/editor/QuillUpdate'
import Message from '../components/layout/Message'
import Loader from '../components/layout/Loader'
import AdminPageContainer from '../components/layout/AdminPageContainer'
import { listProjectDetails, updateProject } from '../actions/projectActions'
import { PROJECT_UPDATE_RESET } from '../constants/projectConstants'
import { logout } from '../actions/userActions'

const ProjectEdit = ({ match, history }) => {
  const projectId = match.params.id

  const [fundingInpIsOpen, setFundingInpIsOpen] = useState(false)
  const [fundingInpForm, setFundingInpForm] = useState(false)
  const [coAppInpIsOpen, setCoAppInpIsOpen] = useState(false)
  const [coAppInpForm, setCoAppInpForm] = useState(false)

  const [title, setTitle] = useState('')
  const [fundedBy, setFundedBy] = useState([])
  const [fundingInst, setFundingInst] = useState('')
  const [startYear, setStartYear] = useState('')
  const [endYear, setEndYear] = useState('')
  const [status, setStatus] = useState('')
  const [otherStatus, setOtherStatus] = useState('')
  const [link, setLink] = useState('')
  const [role, setRole] = useState('')
  const [otherRole, setOtherRole] = useState('')
  const [coApps, setCoApps] = useState([])
  const [coAppName, setCoAppName] = useState('')
  const [body, setBody] = useState('')
  const [files, setFiles] = useState([])

  const [image, setImage] = useState('')
  const [previewSrc, setPreviewSrc] = useState('')
  const [isPreviewAvailable, setIsPreviewAvailable] = useState(false)
  const dropRef = useRef()

  const dispatch = useDispatch()

  const projectDetails = useSelector((state) => state.projectDetails)
  const { loading, error, project } = projectDetails

  const projectUpdate = useSelector((state) => state.projectUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = projectUpdate

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (userInfo) {
      const token =
        JSON.parse(localStorage.getItem('userInfo')) &&
        JSON.parse(localStorage.getItem('userInfo'))['token']

      if (jwt(token).exp < Date.now() / 1000) {
        dispatch(logout())
      } else {
        if (successUpdate) {
          dispatch({ type: PROJECT_UPDATE_RESET })
          history.push('/admin/projects')
        } else {
          if (!project || project._id !== projectId) {
            dispatch(listProjectDetails(projectId))
          } else {
            setTitle(project.title)
            setFundedBy(project.fundedBy)
            setStartYear(project.startYear)
            setEndYear(project.endYear)
            setStatus(project.status)
            setLink(project.link)
            setRole(project.role)
            setCoApps(project.coApps)
            setBody(project.body)
          }
        }
      }
    } else {
      history.push('/admin')
    }
  }, [dispatch, history, userInfo, project, projectId, successUpdate])

  const displayFundingInput = (e) => {
    e.preventDefault()
    setFundingInpIsOpen(!fundingInpIsOpen)
    setFundingInpForm(true)
  }
  const displayCoAppInput = (e) => {
    e.preventDefault()
    setCoAppInpIsOpen(!coAppInpIsOpen)
    setCoAppInpForm(true)
  }

  const addFunding = (e) => {
    e.preventDefault()

    if (fundingInst) {
      setFundedBy(
        fundedBy.concat([
          {
            fundingInstName: fundingInst,
          },
        ])
      )
      setFundingInst('')
    }
  }
  const addCoApp = (e) => {
    e.preventDefault()

    if (coAppName) {
      setCoApps(
        coApps.concat([
          {
            coAppName: coAppName,
          },
        ])
      )
      setCoAppName('')
    }
  }

  const minOffset = 0
  const maxOffset = 60
  const thisYear = new Date().getFullYear()

  let options = []
  for (let i = minOffset; i <= maxOffset; i++) {
    const year = thisYear - i
    options.push(
      <option key={i} value={year}>
        {year}
      </option>
    )
  }

  const onEditorChange = (value) => {
    setBody(value)
  }

  const onFilesChange = (files) => {
    setFiles(files)
  }

  const onDrop = (files) => {
    const [uploadedFile] = files
    setImage(uploadedFile)

    const fileReader = new FileReader()
    fileReader.onload = () => {
      setPreviewSrc(fileReader.result)
    }
    fileReader.readAsDataURL(uploadedFile)
    setIsPreviewAvailable(uploadedFile.name.match(/\.(jpeg|jpg|png)$/))
  }

  const updateBorder = (dragState) => {
    if (dragState === 'over') {
      dropRef.current.style.border = '2px solid #000'
    } else if (dragState === 'leave') {
      dropRef.current.style.border = '2px dashed #e9ebeb'
    }
  }

  const submitHandler = (e) => {
    e.preventDefault()

    const formData = new FormData()

    if (title) formData.append('title', title)
    if (body) formData.append('body', body)
    if (fundedBy) formData.append('fundedBy', JSON.stringify(fundedBy))
    if (coApps) formData.append('coApps', JSON.stringify(coApps))
    if (startYear) formData.append('startYear', startYear)
    if (endYear) formData.append('endYear', endYear)
    if (status) formData.append('status', otherStatus ? otherStatus : status)
    if (role) formData.append('role', otherRole ? otherRole : role)
    if (link) formData.append('link', link)
    if (image) formData.append('image', image)

    dispatch(updateProject(formData, projectId))
  }

  return (
    <AdminPageContainer>
      <div className='list-btns' style={{ marginBottom: '50px' }}>
        <Link to='/admin/projects'>
          <Button className='back-btn'>
            <i className='fas fa-arrow-left' /> Back To Projects
          </Button>
        </Link>
        <Link to='/admin/project/create'>
          <Button
            style={{
              left: '0',
              right: 'auto',
              border: 'none',
              marginLeft: '160px',
            }}
            className='list-btn'
          >
            <i className='fas fa-plus'></i> Create Project
          </Button>
        </Link>
      </div>
      <h2>Update Project</h2>

      <Form>
        {/* Start of Project Title */}
        <Row>
          <Col>
            <Form.Group controlId='title'>
              <Form.Label>
                Title: <span className='asterisc'>*</span>{' '}
              </Form.Label>
              <Form.Control
                type='text'
                placeholder='Project Title'
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>
        {/* End of Project Title */}

        {/* Start of Project years */}
        <Row className='add-years-group'>
          <Col>
            <Form.Group controlId='startYear'>
              <Form.Label>
                From: <span className='asterisc'>*</span>{' '}
              </Form.Label>
              <Form.Control
                type='text'
                as='select'
                placeholder='From'
                value={startYear}
                onChange={(e) => setStartYear(e.target.value)}
              >
                <option>From</option>
                {options}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId='endYear'>
              <Form.Label>
                To: <span className='asterisc'>*</span>{' '}
              </Form.Label>
              <Form.Control
                type='text'
                as='select'
                placeholder='To'
                value={endYear}
                onChange={(e) => setEndYear(e.target.value)}
              >
                <option>To</option>
                {options}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        {/* End of Project years */}

        {/* Start of Project status */}
        <Row>
          <Col>
            <Form.Group controlId='status'>
              <Form.Label>
                Project Status: <span className='asterisc'>*</span>{' '}
              </Form.Label>
              <Form.Control
                type='text'
                as='select'
                placeholder='Select Status'
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option>Select Project Status</option>
                <option>Current</option>
                <option>Past</option>
                <option>Others</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId='otherStatus'>
              <Form.Label className='form-label'>Specify Others:</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter Status'
                value={otherStatus}
                disabled={status === 'Others' ? false : true}
                onChange={(e) => setOtherStatus(e.target.value)}
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>
        {/* End of Project status */}

        {/* Start of funding */}
        <Row className='add-funding-group'>
          <Col>
            <Button onClick={displayFundingInput} className='add-btn'>
              <i className='fas fa-plus'></i>
              <span style={{ marginLeft: '5px' }}>
                Add Funding Organization
              </span>
            </Button>
          </Col>
          <Col>
            {fundingInpIsOpen && (
              <div className='item-input-container'>
                <input
                  type='text'
                  placeholder='Enter Title'
                  value={fundingInst}
                  onChange={(e) => setFundingInst(e.target.value)}
                  className='item-input'
                />
                <button onClick={addFunding} className='item-add-btn'>
                  Add
                </button>
              </div>
            )}
          </Col>
        </Row>
        {/* End of funding */}

        {/* Start of Funding(s) display */}
        <Row className='items-list'>
          <Col>
            {fundedBy &&
              fundedBy.map((f, key) => (
                <Button className='item-btn' key={key}>
                  {f.fundingInstName}{' '}
                  <i
                    className='fas fa-times'
                    onClick={() =>
                      setFundedBy(
                        fundedBy && fundedBy.filter((item, id) => id !== key)
                      )
                    }
                    style={{ marginLeft: '5px' }}
                  ></i>{' '}
                </Button>
              ))}
          </Col>
        </Row>
        {/* End of Funding(s) display */}

        {/* Start of Role */}
        <Row>
          <Col>
            <Form.Group controlId='role'>
              <Form.Label className='form-label'>Role:</Form.Label>
              <Form.Control
                type='text'
                as='select'
                placeholder='Enter role'
                value={role}
                onChange={(e) => setRole(e.target.value)}
              >
                <option>Select Role</option>
                <option>Principal Investigator</option>
                <option>CoApplicant</option>
                <option>Others</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId='otherRole'>
              <Form.Label className='form-label'>Specify Others:</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter Role'
                value={otherRole}
                disabled={role === 'Others' ? false : true}
                onChange={(e) => setOtherRole(e.target.value)}
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>
        {/* End of Role */}

        {role === 'CoApplicant' ? (
          <Fragment>
            {/* Start of coApps */}
            <Row className='add-coapp-group'>
              <Col>
                <Button onClick={displayCoAppInput} className='add-btn'>
                  <i className='fas fa-plus'></i>
                  <span style={{ marginLeft: '5px' }}>Add Co-applicants</span>
                </Button>
              </Col>
              <Col>
                {coAppInpIsOpen && (
                  <div className='item-input-container'>
                    <input
                      type='text'
                      placeholder='Enter Title'
                      value={coAppName}
                      onChange={(e) => setCoAppName(e.target.value)}
                      className='item-input'
                    />
                    <button onClick={addCoApp} className='item-add-btn'>
                      Add
                    </button>
                  </div>
                )}
              </Col>
            </Row>
            {/* End of coApps */}

            {/* Start of CoApp(s) display */}
            <Row className='items-list'>
              <Col>
                {coApps &&
                  coApps.map((co, key) => (
                    <Button className='item-btn' key={key}>
                      {co.coAppName}{' '}
                      <i
                        className='fas fa-times'
                        onClick={() =>
                          setCoApps(
                            coApps && coApps.filter((item, id) => id !== key)
                          )
                        }
                        style={{ marginLeft: '5px' }}
                      ></i>{' '}
                    </Button>
                  ))}
              </Col>
            </Row>
            {/* End of CoApp(s) display */}

            {/* Start of Post Link */}
            <Row>
              <Col>
                <Form.Group controlId='link'>
                  <Form.Label>Link to Project:</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Link (Optional)'
                    value={link}
                    onChange={(e) => setLink(e.target.value)}
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
            {/* End of Post Link */}
          </Fragment>
        ) : (
          <div />
        )}

        {/* Start of Editor */}
        <Row>
          <Col>
            <QuillUpdate
              placeholder='Insert Post Body ...'
              onEditorChange={onEditorChange}
              onFilesChange={onFilesChange}
              editorHtml={body}
            />
          </Col>
        </Row>
        {/* End of Editor */}
      </Form>
      {/* Start of Image Upload */}
      {/* <div className='upload-section'>
        <Dropzone
          onDrop={onDrop}
          onDragEnter={() => updateBorder('over')}
          onDragLeave={() => updateBorder('leave')}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps({ className: 'drop-zone' })} ref={dropRef}>
              <input {...getInputProps()} />
              <p>Drag and drop a file OR click here to select a file</p>
              <p>(Image dimensions should be 200*230 or a ratio of this)</p>

              {image && (
                <div>
                  <strong>Selected file:</strong> {image.name}
                </div>
              )}
            </div>
          )}
        </Dropzone>
        {previewSrc ? (
          isPreviewAvailable ? (
            <div className='image-preview'>
              <img className='preview-image' src={previewSrc} alt='Preview' />
            </div>
          ) : (
            <div className='preview-message'>
              <p>No preview available for this file</p>
            </div>
          )
        ) : (
          <div className='preview-message'>
            <p>Image Preview</p>
          </div>
        )}
      </div> */}

      {/* End of Image Upload */}
      <Button
        type='button'
        variant='primary'
        style={{
          color: '#fff',
          backgroundColor: '#14213d',
          marginTop: '20px',
        }}
        onClick={submitHandler}
      >
        Update
      </Button>
      {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
      {loadingUpdate && <Loader />}
    </AdminPageContainer>
  )
}

export default ProjectEdit

import React, { useState, useEffect, useRef } from 'react'
import jwt from 'jwt-decode'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Button, Row, Col } from 'react-bootstrap'
import Dropzone from 'react-dropzone'
import QuillUpdate from '../components/editor/QuillUpdate'
import Message from '../components/layout/Message'
import Loader from '../components/layout/Loader'
import AdminPageContainer from '../components/layout/AdminPageContainer'
import { listMemberDetails, updateMember } from '../actions/memberActions'
import { MEMBER_UPDATE_RESET } from '../constants/memberConstants'
import { logout } from '../actions/userActions'
import { Fragment } from 'react'

const MemberEdit = ({ match, history }) => {
  const memberId = match.params.id

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [bio, setBio] = useState('')
  const [title, setTitle] = useState('')
  const [status, setStatus] = useState('')
  const [otherTitles, setOtherTitles] = useState('')
  const [otherStatus, setOtherStatus] = useState('')
  const [joined, setJoined] = useState('')
  const [files, setFiles] = useState([])

  const [image, setImage] = useState('')
  const [previewSrc, setPreviewSrc] = useState('')
  const [isPreviewAvailable, setIsPreviewAvailable] = useState(false)
  const dropRef = useRef()

  const dispatch = useDispatch()

  const memberDetails = useSelector((state) => state.memberDetails)
  const { loading, error, member } = memberDetails

  const memberUpdate = useSelector((state) => state.memberUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = memberUpdate

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (userInfo) {
      const token =
        JSON.parse(localStorage.getItem('userInfo')) &&
        JSON.parse(localStorage.getItem('userInfo'))['token']

      if (jwt(token).exp < Date.now() / 1000) {
        dispatch(logout())
      } else {
        if (successUpdate) {
          dispatch({ type: MEMBER_UPDATE_RESET })
          history.push('/admin/members')
        } else {
          if (!member || member._id !== memberId) {
            dispatch(listMemberDetails(memberId))
          } else {
            setName(member.name)
            setEmail(member.email)
            setBio(member.bio)
            setTitle(member.title)
            setStatus(member.status)
            setJoined(member.joined)
            if (titleArr.filter((ti) => ti === member.title).length === 0)
              setOtherTitles(member.title)

            if (statusArr.filter((st) => st === member.status).length === 0)
              setOtherStatus(member.status)
          }
        }
      }
    } else {
      history.push('/admin')
    }
  }, [dispatch, history, member, memberId, successUpdate])

  const titleArr = [
    'Post Doctoral',
    'Doctor of Philosophy',
    'Master of Arts',
    'Master of Science',
  ]
  const statusArr = ['Current', 'Past', 'Partner']

  const onEditorChange = (value) => {
    setBio(value)
  }

  const onFilesChange = (files) => {
    setFiles(files)
  }

  const onDrop = (files) => {
    const [uploadedFile] = files
    setImage(uploadedFile)

    const fileReader = new FileReader()
    fileReader.onload = () => {
      setPreviewSrc(fileReader.result)
    }
    fileReader.readAsDataURL(uploadedFile)
    setIsPreviewAvailable(uploadedFile.name.match(/\.(jpeg|jpg|png)$/))
  }

  const updateBorder = (dragState) => {
    if (dragState === 'over') {
      dropRef.current.style.border = '2px solid #000'
    } else if (dragState === 'leave') {
      dropRef.current.style.border = '2px dashed #e9ebeb'
    }
  }

  const submitHandler = (e) => {
    e.preventDefault()

    const formData = new FormData()

    if (name) formData.append('name', name)
    if (email) formData.append('email', email)
    if (bio) formData.append('bio', bio)
    if (title) formData.append('title', otherTitles ? otherTitles : title)
    if (status) formData.append('status', otherStatus ? otherStatus : status)
    if (joined) formData.append('joined', joined)
    if (image) formData.append('image', image)

    dispatch(updateMember(formData, memberId))
  }

  return (
    <AdminPageContainer>
      <div className='list-btns' style={{ marginBottom: '50px' }}>
        <Link to='/admin/members'>
          <Button className='back-btn'>
            <i className='fas fa-arrow-left' /> Back To Members
          </Button>
        </Link>
        <Link to='/admin/member/create'>
          <Button
            style={{
              left: '0',
              right: 'auto',
              border: 'none',
              marginLeft: '170px',
            }}
            className='list-btn'
          >
            <i className='fas fa-plus' /> Create Member
          </Button>
        </Link>
      </div>
      <h2>Update Member</h2>

      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <Fragment>
          <Form>
            <Row>
              <Col>
                <Form.Group controlId='name'>
                  <Form.Label className='form-label'>
                    Name: <span className='asterisc'>*</span>
                  </Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter name'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId='email'>
                  <Form.Label className='form-label'>
                    Email: <span className='asterisc'>*</span>
                  </Form.Label>
                  <Form.Control
                    type='email'
                    placeholder='Enter email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={8}>
                <Form.Group controlId='title'>
                  <Form.Label className='form-label'>
                    Member Title: <span className='asterisc'>*</span>
                  </Form.Label>
                  <Form.Control
                    type='text'
                    as='select'
                    placeholder='Enter title'
                    value={
                      titleArr.filter((ti) => ti === title)[0]
                        ? titleArr.filter((ti) => ti === title)[0]
                        : 'Others'
                    }
                    onChange={(e) => setTitle(e.target.value)}
                  >
                    <option>Select a Title</option>
                    <option>Post Doctoral</option>
                    <option>Doctor of Philosophy</option>
                    <option>Master of Arts</option>
                    <option>Master of Science</option>
                    <option>Others</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId='otherTitle'>
                  <Form.Label className='form-label'>
                    Specify Others:
                  </Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter Title'
                    value={
                      titleArr.filter((ti) => ti === title)[0]
                        ? ''
                        : otherTitles
                    }
                    disabled={
                      titleArr.filter((ti) => ti === title)[0] ? true : false
                    }
                    onChange={(e) => setOtherTitles(e.target.value)}
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={8}>
                <Form.Group controlId='status'>
                  <Form.Label className='form-label'>
                    Member Status: <span className='asterisc'>*</span>
                  </Form.Label>
                  <Form.Control
                    type='text'
                    as='select'
                    placeholder='Enter status'
                    value={
                      statusArr.filter((st) => st === status)[0]
                        ? statusArr.filter((st) => st === status)[0]
                        : 'Others'
                    }
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option>Select a Status</option>
                    <option>Current</option>
                    <option>Past</option>
                    <option>Partner</option>
                    <option>Others</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId='otherStatus'>
                  <Form.Label className='form-label'>
                    Specify Others:
                  </Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter Status'
                    value={
                      statusArr.filter((st) => st === status)[0]
                        ? ''
                        : otherStatus
                    }
                    disabled={
                      statusArr.filter((st) => st === status)[0] ? true : false
                    }
                    onChange={(e) => setOtherStatus(e.target.value)}
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col></Col>
              <Col lg={4}>
                <Form.Group controlId='joined'>
                  <Form.Label className='form-label'>Joined:</Form.Label>
                  <Form.Control
                    type='date'
                    placeholder='Joined'
                    value={joined}
                    onChange={(e) => setJoined(e.target.value)}
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <QuillUpdate
                  placeholder='Insert Member Bio ...'
                  onEditorChange={onEditorChange}
                  onFilesChange={onFilesChange}
                  editorHtml={bio}
                />
              </Col>
            </Row>
          </Form>

          {/* Start of Image Upload */}
          <div className='upload-section'>
            <Dropzone
              onDrop={onDrop}
              onDragEnter={() => updateBorder('over')}
              onDragLeave={() => updateBorder('leave')}
            >
              {({ getRootProps, getInputProps }) => (
                <div
                  {...getRootProps({ className: 'drop-zone' })}
                  ref={dropRef}
                >
                  <input {...getInputProps()} />
                  <p>Drag and drop a file OR click here to select a file</p>
                  <p>(Image dimensions should be 200*230 or a ratio of this)</p>

                  {image && (
                    <div>
                      <strong>Selected file:</strong> {image.name}
                    </div>
                  )}
                </div>
              )}
            </Dropzone>
            {previewSrc ? (
              isPreviewAvailable ? (
                <div className='image-preview'>
                  <img
                    className='preview-image'
                    src={previewSrc}
                    alt='Preview'
                  />
                </div>
              ) : (
                <div className='preview-message'>
                  <p>No preview available for this file</p>
                </div>
              )
            ) : (
              <div className='preview-message'>
                <p>Image Preview</p>
              </div>
            )}
          </div>

          {/* End of Image Upload */}

          <Button
            type='button'
            variant='primary'
            style={{
              color: '#fff',
              backgroundColor: '#14213d',
              marginTop: '20px',
            }}
            onClick={submitHandler}
          >
            Update
          </Button>
        </Fragment>
      )}
      {loadingUpdate && <Loader />}
      {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
    </AdminPageContainer>
  )
}

export default MemberEdit

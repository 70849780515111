import React from 'react'
import Tabs from './Tabs'
import PeerReviewed from './PeerReviewed'
import SpecialIssues from './SpecialIssues'
import BookChapters from './BookChapters'

const Publications = ({ publications, filteredPublications, loading }) => {
  return (
    <Tabs>
      <div label='Peer Reviewed Journal Articles'>
        <PeerReviewed
          publications={publications}
          filteredPublications={filteredPublications}
          loading={loading}
        />
      </div>
      <div label='Edited Journal Special Issues'>
        <SpecialIssues
          publications={publications}
          filteredPublications={filteredPublications}
          loading={loading}
        />
      </div>
      <div label='Book Chapters'>
        <BookChapters
          publications={publications}
          filteredPublications={filteredPublications}
          loading={loading}
        />
      </div>
      <div label='Others'>{/* <Others /> */}</div>
    </Tabs>
  )
}

export default Publications

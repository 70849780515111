import React from 'react'
import { Link } from 'react-router-dom'
import { Fade } from 'react-reveal'
import avatar from '../../styling/assets/picture.png'

const CardItem = ({ newsItem, className }) => {
  const timeAgo = (prevDate) => {
    const diff = Number(new Date()) - prevDate
    const minute = 60 * 1000
    const hour = minute * 60
    const day = hour * 24
    const month = day * 30
    const year = day * 365
    switch (true) {
      case diff < minute:
        const seconds = Math.round(diff / 1000)
        return `${seconds} ${seconds > 1 ? 'seconds' : 'second'} ago`
      case diff < hour:
        return Math.round(diff / minute) + ' minutes ago'
      case diff < day:
        return Math.round(diff / hour) + ' hours ago'
      case diff < month:
        return Math.round(diff / day) + ' days ago'
      case diff < year:
        return Math.round(diff / month) + ' months ago'
      case diff > year:
        return Math.round(diff / year) + ' years ago'
      default:
        return ''
    }
  }

  return (
    <>
      {(newsItem && newsItem.source === '') ||
      (newsItem && newsItem.source === undefined) ? (
        <Fade>
          <div className={className}>
            <div className='card__media'>
              <img
                src={newsItem && newsItem.image ? '/' + newsItem.image : avatar}
                // alt='avatar'
              />
            </div>
            <div className='card__info'>
              <h4 className='card__title'>{newsItem && newsItem.title}</h4>
              <div className='fixed__container'>
                <p className='card__update__info'>
                  Updated
                  <span>
                    {timeAgo(
                      new Date(newsItem && newsItem.updatedAt).getTime()
                    )}
                  </span>
                </p>
                {newsItem && newsItem.link ? (
                  <a
                    href={newsItem && newsItem.link}
                    target='_blank'
                    className='card__link'
                  >
                    <button className='blog__item__btn'>
                      READ MORE{' '}
                      <span>
                        <i className='fas fa-chevron-right' />
                      </span>
                    </button>
                  </a>
                ) : (
                  <Link
                    to={`/latest/${newsItem && newsItem._id}`}
                    className='card__link'
                  >
                    <button className='blog__item__btn'>
                      READ MORE{' '}
                      <span>
                        <i className='fas fa-chevron-right' />
                      </span>
                    </button>
                  </Link>
                )}
                {/* <Link
                  to={`/latest/${newsItem && newsItem._id}`}
                  className='card__link'
                >
                  LEARN MORE
                  <span>
                    <i className='fas fa-chevron-right' />
                  </span>
                </Link> */}
              </div>
            </div>
          </div>
        </Fade>
      ) : (
        <Fade>
          <div className={className}>
            <div className='card__media'>
              <iframe
                src={newsItem && newsItem.source}
                frameBorder='0'
              ></iframe>
            </div>
            <div className='card__info'>
              <h4 className='card__title'>{newsItem && newsItem.title}</h4>
              <div className='fixed__container'>
                <p className='card__update__info'>
                  Updated
                  <span>
                    {timeAgo(
                      new Date(newsItem && newsItem.updatedAt).getTime()
                    )}
                  </span>
                </p>
                {newsItem && newsItem.link ? (
                  <a
                    href={newsItem && newsItem.link}
                    target='_blank'
                    className='card__link'
                  >
                    <button className='blog__item__btn'>
                      READ MORE{' '}
                      <span>
                        <i className='fas fa-chevron-right' />
                      </span>
                    </button>
                  </a>
                ) : (
                  <Link
                    to={`/latest/${newsItem && newsItem._id}`}
                    className='card__link'
                  >
                    <button className='blog__item__btn'>
                      READ MORE{' '}
                      <span>
                        <i className='fas fa-chevron-right' />
                      </span>
                    </button>
                  </Link>
                )}
                {/* <Link
                  to={`/latest/${newsItem && newsItem._id}`}
                  className='card__link'
                >
                  LEARN MORE
                  <span>
                    <i className='fas fa-chevron-right' />
                  </span>
                </Link> */}
              </div>
            </div>
          </div>
        </Fade>
      )}
    </>
  )
}

export default CardItem

// import React, { Fragment } from 'react'

// const CardItem = ({ newsItem, className }) => {
//   const timeAgo = (prevDate) => {
//     const diff = Number(new Date()) - prevDate
//     const minute = 60 * 1000
//     const hour = minute * 60
//     const day = hour * 24
//     const month = day * 30
//     const year = day * 365
//     switch (true) {
//       case diff < minute:
//         const seconds = Math.round(diff / 1000)
//         return `${seconds} ${seconds > 1 ? 'seconds' : 'second'} ago`
//       case diff < hour:
//         return Math.round(diff / minute) + ' minutes ago'
//       case diff < day:
//         return Math.round(diff / hour) + ' hours ago'
//       case diff < month:
//         return Math.round(diff / day) + ' days ago'
//       case diff < year:
//         return Math.round(diff / month) + ' months ago'
//       case diff > year:
//         return Math.round(diff / year) + ' years ago'
//       default:
//         return ''
//     }
//   }
//   return (
//     <Fragment>
//       {(newsItem && newsItem.source === '') ||
//       (newsItem && newsItem.source === undefined) ? (
//         <div className={className}>
//           <img src={newsItem && newsItem.image} className='card-image' />

//           <div className='card-info'>{newsItem && newsItem.title}</div>
//           <p
//             style={{
//               fontSize: '0.8rem',
//               fontWeight: '300',
//               paddingBottom: '5px',
//             }}
//           >
//             Updated:{' '}
//             <span>
//               {timeAgo(new Date(newsItem && newsItem.updatedAt).getTime())}
//             </span>
//           </p>
//           <a
//             href={
//               newsItem && newsItem.link
//                 ? newsItem.link
//                 : `/latest/${newsItem._id}`
//             }
//             target='_blank'
//             className='card-btn'
//           >
//             Learn More{' '}
//             <span style={{ marginLeft: '5px' }}>
//               <i className='fas fa-chevron-right'></i>
//             </span>
//           </a>
//         </div>
//       ) : (
//         <div className={className}>
//           <iframe
//             src={newsItem && newsItem.source}
//             width='460'
//             height='430'
//             frameBorder='0'
//             className='card-image'
//           ></iframe>

//           <div className='card-info'>{newsItem && newsItem.title}</div>
//           <p
//             style={{
//               fontSize: '0.8rem',
//               fontWeight: '300',
//               paddingBottom: '5px',
//             }}
//           >
//             Updated:{' '}
//             <span>
//               {timeAgo(new Date(newsItem && newsItem.updatedAt).getTime())}
//             </span>
//           </p>

//           <a
//             href={
//               newsItem && newsItem.link
//                 ? newsItem.link
//                 : `/latest/${newsItem && newsItem._id}`
//             }
//             target='_blank'
//             className='card-btn'
//           >
//             Learn More{' '}
//             <span style={{ marginLeft: '5px' }}>
//               <i className='fas fa-chevron-right'></i>
//             </span>
//           </a>
//         </div>
//       )}
//     </Fragment>
//   )
// }

// export default CardItem

import React from 'react'

const NewsNavItem = ({ newsItem, active }) => {
  const { title, _id, createdAt } = newsItem

  return (
    <div className={`blog__nav__item ${_id === active ? 'active' : ''}`}>
      <a href={`/latest/${_id}`}>{title}</a>
      <p>{createdAt && createdAt.slice(0, 10)}</p>
    </div>
  )
}

export default NewsNavItem

import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <div id='footer'>
      <div id='footer__map'>
        <Link to='/' className='footer__link'>
          HOME
        </Link>

        <Link to='/members' className='footer__link'>
          MEMBERS
        </Link>

        <Link to='/research' className='footer__link'>
          RESEARCH
        </Link>

        <Link to='/latest' className='footer__link'>
          NEWS & EVENTS
        </Link>
      </div>

      <div id='footer__contact'>
        <p id='footer__contact__title'>
          <span style={{ fontSize: '1.4rem', fontWeight: '500' }}>
            Prospective students
          </span>
          can reach us through:
        </p>
        <a href='mailto:blane.harvey@mcgill.ca'>
          <span>
            <i className='fas fa-envelope' />
          </span>
          blane.harvey@mcgill.ca
        </a>
        <span>
          <p>
            <span>
              <i className='fas fa-university' />
            </span>
            <a href='https://www.mcgill.ca/dise/' target='_blank'>
              Department of Integrated Studies in Education,{' '}
            </a>
            <span>McGill University Faculty of Education.</span>
          </p>
        </span>
      </div>
    </div>
  )
}

export default Footer

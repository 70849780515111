import React, { Fragment, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Button } from 'react-bootstrap'
import Message from '../components/layout/Message'
import Loader from '../components/layout/Loader'
import { login } from '../actions/userActions'

const UserLogin = ({ history }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin

  useEffect(() => {
    if (userInfo) {
      history.push('/admin/dashboard')
    }
  }, [history, userInfo])

  const submitHandler = (e) => {
    e.preventDefault()
    // Dispatch login
    dispatch(login(email, password))
  }

  return (
    <Fragment>
      <div id='login-screen' />
      <div id='login'>
        <h3 className='title login-title' style={{ color: '#495057' }}>
          Login To Admin Panel
        </h3>

        <Form onSubmit={submitHandler} id='login-form'>
          <Form.Group controlId='email'>
            <Form.Label>
              Email <span className='asterisc'>*</span>{' '}
            </Form.Label>
            <Form.Control
              type='email'
              placeholder='Enter email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId='password'>
            <Form.Label>
              Password <span className='asterisc'>*</span>{' '}
            </Form.Label>
            <Form.Control
              type='password'
              placeholder='Enter password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            ></Form.Control>
            {/* <h6 id='login-forget-pass'>
              <Link to='#!' className='forget-pass-link'>
                Forgot password?
              </Link>
            </h6> */}
          </Form.Group>

          <Button type='submit' variant='primary' className='function-btn'>
            Login
          </Button>
        </Form>
        <div style={{ marginTop: '10px' }}>
          {error && <Message variant='danger'>{error}</Message>}
          {loading && <Loader />}
        </div>
      </div>
    </Fragment>
  )
}

export default UserLogin

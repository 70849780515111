import React, { useEffect } from 'react'
import jwt from 'jwt-decode'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/layout/Message'
import Loader from '../components/layout/Loader'
import AdminPageContainer from '../components/layout/AdminPageContainer'
import { listMessageDetails } from '../actions/messageActions'
import { logout } from '../actions/userActions'
import { Button } from 'react-bootstrap'

const MessageView = ({ match, history }) => {
  const dispatch = useDispatch()

  const messageDetails = useSelector((state) => state.messageDetails)
  const { loading, error, message } = messageDetails

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (userInfo) {
      const token =
        JSON.parse(localStorage.getItem('userInfo')) &&
        JSON.parse(localStorage.getItem('userInfo'))['token']

      if (jwt(token).exp < Date.now() / 1000) {
        dispatch(logout)
        // localStorage.removeItem('userInfo')
        // history.push('/admin')
      } else {
        dispatch(listMessageDetails(match.params.id))
      }
    } else {
      history.push('/admin')
    }
  }, [dispatch, history, userInfo, match])

  return (
    <AdminPageContainer>
      <div className='list-btns'>
        <Link to='/admin/messages'>
          <Button className='back-btn'>
            <i class='fas fa-arrow-left' /> Back To Messages
          </Button>
        </Link>
      </div>
      <div className='list-child' style={{ marginTop: '50px' }}>
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error}</Message>
        ) : (
          <div>
            <a
              style={{ fontWeight: '700', display: 'block', color: '#577590' }}
            >
              From:{' '}
              <span style={{ marginLeft: '8px', color: '#495057' }}>
                {message.name}
              </span>{' '}
            </a>
            <a
              style={{ fontWeight: '700', display: 'inline', color: '#577590' }}
            >
              Email:
            </a>
            <a
              href={`mailto:${message.email}`}
              style={{
                display: 'inline',
                marginLeft: '8px',
                color: 'blue',
                textDecoration: 'underline',
              }}
            >
              {message.email}
            </a>
            <a
              style={{ fontWeight: '700', display: 'block', color: '#577590' }}
            >
              Subject:{' '}
              <span style={{ marginLeft: '8px', color: '#495057' }}>
                {message.subject}
              </span>{' '}
            </a>
            <a
              style={{ fontWeight: '700', display: 'block', color: '#577590' }}
            >
              Date:{' '}
              <span style={{ marginLeft: '8px', color: '#495057' }}>
                {message.createdAt && message.createdAt.slice(0, 10)}
              </span>{' '}
            </a>
            <div style={{ margin: '20px 0' }}>
              <a
                style={{
                  fontWeight: '700',
                  display: 'block',
                  color: '#577590',
                }}
              >
                Message:
              </a>
              <p>{message.body}</p>
            </div>
          </div>
        )}
      </div>
    </AdminPageContainer>
  )
}

export default MessageView

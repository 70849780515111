import React, { useState, useEffect, useRef } from 'react'
import jwt from 'jwt-decode'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Button, Row, Col } from 'react-bootstrap'
import Dropzone from 'react-dropzone'
import QuillEditor from '../components/editor/QuillEditor'
import Message from '../components/layout/Message'
import Loader from '../components/layout/Loader'
import AdminPageContainer from '../components/layout/AdminPageContainer'
import { createMember } from '../actions/memberActions'
import { MEMBER_CREATE_RESET } from '../constants/memberConstants'
import { logout } from '../actions/userActions'

const MemberCreate = ({ history }) => {
  const [nameErr, setNameErr] = useState(false)
  const [emailErr, setEmailErr] = useState(false)
  const [titleErr, setTitleErr] = useState(false)
  const [bioErr, setBioErr] = useState(false)
  const [statusErr, setStatusErr] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [bio, setBio] = useState('')
  const [title, setTitle] = useState('')
  const [status, setStatus] = useState('')
  const [otherTitles, setOtherTitles] = useState('')
  const [otherStatus, setOtherStatus] = useState('')
  const [joined, setJoined] = useState('')
  const [files, setFiles] = useState([])

  const [image, setImage] = useState('')
  const [previewSrc, setPreviewSrc] = useState('')
  const [isPreviewAvailable, setIsPreviewAvailable] = useState(false)
  const dropRef = useRef()

  const dispatch = useDispatch()

  const memberCreate = useSelector((state) => state.memberCreate)
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
  } = memberCreate

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (userInfo) {
      const token =
        JSON.parse(localStorage.getItem('userInfo')) &&
        JSON.parse(localStorage.getItem('userInfo'))['token']

      if (jwt(token).exp < Date.now() / 1000) {
        dispatch(logout())
      } else {
        if (successCreate) {
          dispatch({ type: MEMBER_CREATE_RESET })

          history.push('/admin/members')
        }
      }
    } else {
      history.push('/admin')
    }
  }, [dispatch, history, userInfo, successCreate])

  const onEditorChange = (value) => {
    setBio(value)
  }

  const onFilesChange = (files) => {
    setFiles(files)
  }

  const onDrop = (files) => {
    const [uploadedFile] = files
    setImage(uploadedFile)

    console.log(uploadedFile)

    const fileReader = new FileReader()
    fileReader.onload = () => {
      setPreviewSrc(fileReader.result)
    }
    fileReader.readAsDataURL(uploadedFile)
    setIsPreviewAvailable(uploadedFile.name.match(/\.(jpeg|jpg|png)$/))
  }

  const updateBorder = (dragState) => {
    if (dragState === 'over') {
      dropRef.current.style.border = '2px solid #000'
    } else if (dragState === 'leave') {
      dropRef.current.style.border = '2px dashed #e9ebeb'
    }
  }

  const submitHandler = async (e) => {
    e.preventDefault()

    const formData = new FormData()

    if (name) {
      formData.append('name', name)
    } else {
      setNameErr(true)
    }
    if (email) {
      formData.append('email', email)
    } else {
      setEmailErr(true)
    }
    if (bio) {
      formData.append('bio', `This is ${name}'s biography.`)
    } else {
      setBioErr(true)
    }
    if (title) {
      formData.append('title', otherTitles ? otherTitles : title)
    } else {
      setTitleErr(true)
    }
    if (status) {
      formData.append('status', otherStatus ? otherStatus : status)
    } else {
      setStatusErr(true)
    }
    if (joined) formData.append('joined', joined)
    if (image) formData.append('image', image)

    dispatch(createMember(formData))
  }

  return (
    <AdminPageContainer>
      <div className='list-btns' style={{ marginBottom: '50px' }}>
        <Link to='/admin/members'>
          <Button className='back-btn'>
            <i className='fas fa-arrow-left' /> Back To Members
          </Button>
        </Link>
      </div>
      <h2>Create Member</h2>

      <Form>
        <Row>
          <Col>
            <Form.Group controlId='name'>
              <Form.Label className='form-label'>
                Name: <span className='asterisc'>*</span>
              </Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter name'
                value={name}
                onChange={(e) => setName(e.target.value)}
              ></Form.Control>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId='email'>
              <Form.Label className='form-label'>
                Email: <span className='asterisc'>*</span>
              </Form.Label>
              <Form.Control
                type='email'
                placeholder='Enter email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col lg={8}>
            <Form.Group controlId='title'>
              <Form.Label className='form-label'>
                Member Title: <span className='asterisc'>*</span>
              </Form.Label>
              <Form.Control
                type='text'
                as='select'
                placeholder='Enter title'
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              >
                <option>Select a Title</option>
                <option>Post Doctoral</option>
                <option>Doctor of Philosophy</option>
                <option>Master of Arts</option>
                <option>Master of Science</option>
                <option>Others</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId='otherTitle'>
              <Form.Label className='form-label'>Specify Others:</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter Title'
                disabled={title === 'Others' ? false : true}
                onChange={(e) => setOtherTitles(e.target.value)}
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col lg={8}>
            <Form.Group controlId='status'>
              <Form.Label className='form-label'>
                Member Status: <span className='asterisc'>*</span>
              </Form.Label>
              <Form.Control
                type='text'
                as='select'
                placeholder='Enter status'
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option>Select a Status</option>
                <option>Current</option>
                <option>Past</option>
                <option>Partner</option>
                <option>Others</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId='otherStatus'>
              <Form.Label className='form-label'>Specify Others:</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter Status'
                value={otherStatus}
                disabled={status === 'Others' ? false : true}
                onChange={(e) => setOtherStatus(e.target.value)}
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col></Col>
          <Col lg={4}>
            <Form.Group controlId='joined'>
              <Form.Label className='form-label'>Joined:</Form.Label>
              <Form.Control
                type='date'
                placeholder='Joined'
                value={joined}
                onChange={(e) => setJoined(e.target.value)}
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <QuillEditor
              placeholder='Insert Member Bio ...'
              onEditorChange={onEditorChange}
              onFilesChange={onFilesChange}
            />
          </Col>
        </Row>
      </Form>

      {/* Start of Image Upload */}
      <div className='upload-section'>
        <Dropzone
          onDrop={onDrop}
          onDragEnter={() => updateBorder('over')}
          onDragLeave={() => updateBorder('leave')}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps({ className: 'drop-zone' })} ref={dropRef}>
              <input {...getInputProps()} />
              <p>Drag and drop an image OR click here to select an image</p>
              <p>(Image dimensions should be 200*230 or a ratio of this)</p>
              {image && (
                <div>
                  <strong>Selected file:</strong> {image.name}
                </div>
              )}
            </div>
          )}
        </Dropzone>
        {previewSrc ? (
          isPreviewAvailable ? (
            <div className='image-preview'>
              <img className='preview-image' src={previewSrc} alt='Preview' />
            </div>
          ) : (
            <div className='preview-message' style={{ height: '350px' }}>
              <p>No preview available for this image</p>
            </div>
          )
        ) : (
          <div className='preview-message'>
            <p>Image Preview</p>
          </div>
        )}
      </div>
      {/* End of Image Upload */}

      <Button
        type='button'
        variant='primary'
        style={{
          color: '#fff',
          backgroundColor: '#14213d',
          marginTop: '20px',
        }}
        onClick={submitHandler}
      >
        Create
      </Button>
      {/* {errorCreate && <Message variant='danger'>{errorCreate}</Message>} */}
      {nameErr === true ? (
        <Message variant='danger'>Name is required.</Message>
      ) : emailErr === true ? (
        <Message variant='danger'>Email is required.</Message>
      ) : bioErr === true ? (
        <Message variant='danger'>Member Bio is required.</Message>
      ) : titleErr === true ? (
        <Message variant='danger'>Member Title is required.</Message>
      ) : statusErr === true ? (
        <Message variant='danger'>Member Status is required.</Message>
      ) : (
        ''
      )}
      {loadingCreate && <Loader />}
    </AdminPageContainer>
  )
}

export default MemberCreate

import React, { useEffect, useLayoutEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { listNewsDetails } from '../../actions/newsActions'
import { listMembers } from '../../actions/memberActions'
import Loader from '../layout/Loader'
import Message from '../layout/Message'
import NewsHiddenNav from '../news/NewsHiddenNav'
import SocialMedia from '../layout/SocialMedia'
import Footer from '../layout/Footer'
import DefaultBg from '../../styling/assets/Headers/default.jpg'
import avatar from '../../styling/assets/avatar.png'

const BlogPost = ({ match }) => {
  const dispatch = useDispatch()
  const blogId = match.params.id

  const newsDetails = useSelector((state) => state.newsDetails)
  const { loading, error, newsItem } = newsDetails

  const memberList = useSelector((state) => state.memberList)
  const { members } = memberList

  useEffect(() => {
    dispatch(listNewsDetails(blogId))
    dispatch(listMembers())

    window.onscroll = function () {
      scrollFunction()
    }

    const scrollFunction = () => {
      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
      ) {
        document.getElementById('social__media__container') &&
          document
            .getElementById('social__media__container')
            .classList.add('apply__offset')
      } else {
        document.getElementById('social__media__container') &&
          document
            .getElementById('social__media__container')
            .classList.remove('apply__offset')
      }
    }
  }, [dispatch, blogId, window])

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const investigateAuthors =
    newsItem &&
    newsItem.authors &&
    newsItem.authors.map((author) => {
      return (
        members && members.filter((member) => member.name === author.authorName)
      )
    })

  const facebookAppId = 490875188871275
  const postUrl = encodeURI(
    'https://learn2sustain.ca/latest/619fc30f9bcc06170107171c'
  )

  return (
    <div className='blog__screen'>
      {/* Blog screen Header */}
      <img
        src={newsItem && newsItem.image ? '/' + newsItem.image : DefaultBg}
        // src={DefaultBg}
        alt='blog-header'
        className='blog__screen__header'
      />
      {/* Blog Screen Body */}
      <div className='back__link'>
        <button>
          <Link to='/latest' className='back__link__btn'>
            Back To Posts
          </Link>
        </button>
      </div>
      <div className='blog__screen__container'>
        <div>
          <div className='share__actions' id='social__media__container'>
            <SocialMedia
              title={newsItem && newsItem.title}
              image={newsItem && newsItem.image}
            />
          </div>
        </div>

        <div className='blog__main'>
          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant='danger'>{error}</Message>
          ) : (
            <div className='blog__body'>
              <div className='blog__posted'>
                <h6>
                  {newsItem.createdAt &&
                    new Date(newsItem.createdAt).toUTCString()}
                </h6>
                {/* <h6>{newsItem.createdAt && newsItem.createdAt.slice(0, 10)}</h6> */}
              </div>
              <h1 className='blog__title'>{newsItem && newsItem.title}</h1>
              <h5 className='blog__authors'>
                {newsItem &&
                  newsItem.authors &&
                  newsItem.authors.map((author, id) => (
                    <span key={id} className='blog__author'>
                      <img
                        src={
                          investigateAuthors && investigateAuthors[id][0]
                            ? '/' + investigateAuthors[id][0].image
                            : avatar
                        }
                        // alt='author'
                      />
                      <span>{author.authorName}</span>
                    </span>
                  ))}
              </h5>

              <div
                dangerouslySetInnerHTML={{ __html: newsItem && newsItem.body }}
                className='blog__content'
              />
            </div>
          )}
        </div>
        <div className='blog__navbar'>
          <NewsHiddenNav active={match.params.id} />
          <a href='/latest'>View All</a>
        </div>
      </div>

      {/* Footer */}
      <Footer />
    </div>
  )
}

export default BlogPost

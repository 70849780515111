import React, { useRef, useState } from 'react'
import Loader from '../layout/Loader'
import Chevron from './Chevron'
import ProjectItem from './ProjectItem'

const ProjectsAccordion = ({
  projects,
  loading,
  filteredProjects,
  loadingFilter,
}) => {
  const content = useRef(null)

  const [setActive, setActiveState] = useState('')
  const [setHeight, setHeightState] = useState('0px')
  const [setRotate, setRotateState] = useState('accordion__chevron')

  const toggleAccordion = () => {
    setActiveState(setActive === '' ? 'active' : '')
    setHeightState(
      setActive === 'active' ? '0px' : `${content.current.scrollHeight}px`
    )
    setRotateState(
      setActive === 'active'
        ? 'accordion__chevron'
        : 'accordion__chevron rotate'
    )
  }

  const projectsToDisplay = projects && projects.slice(0, 3)
  const projectsToHide =
    projects && projects.slice(3, projects && projects.length)

  return (
    <>
      {filteredProjects && filteredProjects.length > 0 ? (
        <div className='filtered__projects__container'>
          {loadingFilter ? (
            <Loader />
          ) : (
            filteredProjects.map((project, id) => (
              <ProjectItem key={id} project={project} />
            ))
          )}
        </div>
      ) : (
        <div className='projects__accordion'>
          <div className='accordion'>
            {/* Projects: Three Project Items */}
            {loading ? (
              <Loader />
            ) : (
              projectsToDisplay.map((project, id) => (
                <ProjectItem key={id} project={project} />
              ))
            )}
          </div>
          <div
            ref={content}
            style={{ maxHeight: `${setHeight}` }}
            className='accordion__content'
          >
            {projectsToHide.map((project, id) => (
              <ProjectItem key={id} project={project} />
            ))}
          </div>
          {/* Toggle Button */}
          <button
            className={`toggle__btn ${setActive}`}
            onClick={toggleAccordion}
          >
            {setActive === '' ? 'View More' : 'View Less'}
            <Chevron className={`${setRotate}`} width={10} fill={'#777'} />
          </button>
        </div>
      )}
    </>
  )
}

export default ProjectsAccordion

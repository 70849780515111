import React, { useEffect } from 'react'
import jwt from 'jwt-decode'
import { Link } from 'react-router-dom'
import { Table, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/layout/Message'
import Loader from '../components/layout/Loader'
import AdminPageContainer from '../components/layout/AdminPageContainer'
import { listMembers, deleteMember } from '../actions/memberActions'
import { logout } from '../actions/userActions'

const MembersList = ({ history }) => {
  const dispatch = useDispatch()

  const memberList = useSelector((state) => state.memberList)
  const { loading, error, members } = memberList

  const memberDelete = useSelector((state) => state.memberDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = memberDelete

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (userInfo) {
      const token =
        JSON.parse(localStorage.getItem('userInfo')) &&
        JSON.parse(localStorage.getItem('userInfo'))['token']

      if (jwt(token).exp < Date.now() / 1000) {
        dispatch(logout())
      } else {
        dispatch(listMembers())
      }
    } else {
      history.push('/admin')
    }
  }, [dispatch, history, userInfo, successDelete])

  return (
    <AdminPageContainer>
      <div className='list-btns'>
        <Link to='/admin/member/create'>
          <Button
            style={{ left: '0', right: 'auto', border: 'none' }}
            className='list-btn'
          >
            <i className='fas fa-plus'></i> Create Member
          </Button>
        </Link>
      </div>
      <div style={{ marginTop: '60px' }}>
        {loadingDelete && <Loader />}
        {errorDelete && <Message variant='danger'>{errorDelete}</Message>}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error}</Message>
        ) : (
          <Table
            bordered
            responsive
            className='table-sm'
            variant='light'
            style={{ color: '#495057' }}
          >
            <thead>
              <tr>
                <td>#</td>
                <td>Name</td>
                <td>Title</td>
                <td>Status</td>
                <td></td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {members.map((member) => (
                <tr key={member._id}>
                  <td className='icon'>{members.indexOf(member) + 1}</td>
                  <td>
                    {' '}
                    {/* <img src={member.file} className='member-avatar' />{' '} */}
                    {member.name}
                  </td>
                  <td>{member.title}</td>
                  <td>{member.status}</td>
                  <td className='icon'>
                    <Link to={`/admin/member/${member._id}/edit`}>
                      <i className='fas fa-edit edit-icon'></i>
                    </Link>
                  </td>
                  <td className='icon'>
                    <i
                      className='fas fa-trash-alt delete-icon'
                      onClick={() => {
                        if (window.confirm('Are you sure?')) {
                          dispatch(deleteMember(member._id))
                        }
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </div>
    </AdminPageContainer>
  )
}

export default MembersList

import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  filterProjects,
  clearFilterProjects,
} from '../../actions/projectActions'
import {
  filterPublications,
  clearFilterPublications,
} from '../../actions/publicationActions'

const Search = () => {
  const dispatch = useDispatch()
  const [input, setInput] = useState('')

  const handleFilter = (e) => {
    e.preventDefault()
    if (input) {
      dispatch(filterProjects(input))
      dispatch(filterPublications(input))
    }
  }

  const handleClearFilter = () => {
    dispatch(clearFilterProjects())
    dispatch(clearFilterPublications())
  }

  return (
    <>
      <form onSubmit={handleFilter} id='research__filter'>
        <h3 className='search__title'>
          Explore our Projects, Publications and Research Outputs
        </h3>
        <div className='search__controller__box'>
          <div className='search__controller'>
            <i className='bi bi-search search__icon' />
            <input
              type='text'
              name='q'
              value={input}
              onChange={(e) => setInput(e.target.value)}
            />
            <button type='submit' className='search__btn'>
              Search
            </button>
          </div>
          <p onClick={handleClearFilter}>Clear Filter</p>
        </div>
      </form>
    </>
  )
}

export default Search

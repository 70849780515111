import React, { useEffect, useState } from 'react'
import jwt from 'jwt-decode'
import { useDispatch, useSelector } from 'react-redux'
import AdminPageContainer from '../components/layout/AdminPageContainer'
import { listMembers } from '../actions/memberActions'
import { listMessages } from '../actions/messageActions'
import { listNews } from '../actions/newsActions'
import { listProjects } from '../actions/projectActions'
import { listPublications } from '../actions/publicationActions'
import { logout } from '../actions/userActions'

const Dashboard = ({ history }) => {
  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const memberList = useSelector((state) => state.memberList)
  const { members } = memberList

  const messageList = useSelector((state) => state.messageList)
  const { messages } = messageList

  const newsList = useSelector((state) => state.newsList)
  const { news } = newsList

  const projectList = useSelector((state) => state.projectList)
  const { projects } = projectList

  const publicationList = useSelector((state) => state.publicationList)
  const { publications } = publicationList

  useEffect(() => {
    if (userInfo) {
      const token =
        JSON.parse(localStorage.getItem('userInfo')) &&
        JSON.parse(localStorage.getItem('userInfo'))['token']
      if (jwt(token).exp < Date.now() / 1000) {
        dispatch(logout())
      } else {
        dispatch(listMembers())
        dispatch(listMessages())
        dispatch(listNews())
        dispatch(listProjects())
        dispatch(listPublications())
      }
    } else {
      history.push('/admin')
    }
  }, [history, userInfo, dispatch])

  const numOfMembers = members && members.length
  const numOfMessages = messages && messages.length
  const numOfNews = news && news.length
  const numOfProjects = projects && projects.length
  const numOfPublications = publications && publications.length

  return (
    <AdminPageContainer>
      <div id='dash-container'>
        <h3>Welcome To The LL4S Lab Admin Dashboard</h3>
        <h5>Panel Summary:</h5>
        <ul className='dashboard-list'>
          <li>
            You have <span>{numOfMembers} members</span> added to the panel.
          </li>
          <li>
            You have <span>{numOfMessages} messages</span> in the panel.
          </li>
          <li>
            You have <span>{numOfNews} blog posts</span> added to the panel.
          </li>
          <li>
            You have <span>{numOfProjects} projects </span> added to the panel.
          </li>
          <li>
            You have <span>{numOfPublications} publications</span> added to the
            panel.
          </li>
        </ul>
      </div>
    </AdminPageContainer>
  )
}

export default Dashboard

import React, { useState, useEffect, useRef } from 'react'
import jwt from 'jwt-decode'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Button, Row, Col } from 'react-bootstrap'
import Dropzone from 'react-dropzone'
import QuillUpdate from '../components/editor/QuillUpdate'
import Message from '../components/layout/Message'
import Loader from '../components/layout/Loader'
import AdminPageContainer from '../components/layout/AdminPageContainer'
import { listUserDetails, updateUser, logout } from '../actions/userActions'
import { USER_UPDATE_RESET } from '../constants/userConstants'
import { Fragment } from 'react'

const UserEdit = ({ match, history }) => {
  const userId = match.params.id

  const [titleInpIsOpen, setTitleInpIsOpen] = useState(false)
  const [titleInpForm, setTitleInpForm] = useState(false)
  const [expInpIsOpen, setExpInpIsOpen] = useState(false)
  const [expInpForm, setExpInpForm] = useState(false)
  const [intInpIsOpen, setIntInpIsOpen] = useState(false)
  const [intInpForm, setIntInpForm] = useState(false)

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [bio, setBio] = useState('')
  const [titles, setTitles] = useState([])
  const [titleInput, setTitleInput] = useState('')
  const [areasOfExpertise, setAreasOfExpertise] = useState([])
  const [expInput, setExpInput] = useState('')
  const [areasOfInterest, setAreasOfInterest] = useState([])
  const [intInput, setIntInput] = useState('')
  const [password, setPassword] = useState('')
  const [image, setImage] = useState('')
  const [files, setFiles] = useState([])

  const [previewSrc, setPreviewSrc] = useState('')
  const [isPreviewAvailable, setIsPreviewAvailable] = useState(false)
  const dropRef = useRef()

  const dispatch = useDispatch()

  const userDetails = useSelector((state) => state.userDetails)
  const { loading, error, user } = userDetails

  const userUpdate = useSelector((state) => state.userUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = userUpdate

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (userInfo) {
      const token =
        JSON.parse(localStorage.getItem('userInfo')) &&
        JSON.parse(localStorage.getItem('userInfo'))['token']

      if (jwt(token).exp < Date.now() / 1000) {
        dispatch(logout())
      } else {
        if (successUpdate) {
          dispatch({ type: USER_UPDATE_RESET })

          history.push('/admin/users')
        } else {
          if (!user || user._id !== userId) {
            dispatch(listUserDetails(userId))
          } else {
            setName(user.name)
            setEmail(user.email)
            setBio(user.bio)
            setTitles(user.titles)
            setAreasOfExpertise(user.areasOfExpertise)
            setAreasOfInterest(user.areasOfInterest)
            setPassword(user.password)
          }
        }
      }
    } else {
      history.push('/admin')
    }
  }, [dispatch, history, user, userInfo, userId, successUpdate])

  const displayTitleInput = (e) => {
    e.preventDefault()
    setTitleInpIsOpen(!titleInpIsOpen)
    setTitleInpForm(true)
  }

  const displayExpInput = (e) => {
    e.preventDefault()
    setExpInpIsOpen(!expInpIsOpen)
    setExpInpForm(true)
  }

  const displayIntInput = (e) => {
    e.preventDefault()
    setIntInpIsOpen(!intInpIsOpen)
    setIntInpForm(true)
  }

  const addTitle = (e) => {
    e.preventDefault()

    if (titleInput) {
      setTitles(
        titles.concat([
          {
            titleName: titleInput,
          },
        ])
      )
      setTitleInput('')
    }
  }

  const addAreaOfExp = (e) => {
    e.preventDefault()

    if (expInput) {
      setAreasOfExpertise(
        areasOfExpertise.concat([
          {
            expertiseName: expInput,
          },
        ])
      )
      setExpInput('')
    }
  }

  const addAreaOfInt = (e) => {
    e.preventDefault()

    if (intInput) {
      setAreasOfInterest(
        areasOfInterest.concat([
          {
            interestName: intInput,
          },
        ])
      )
      setIntInput('')
    }
  }

  const onEditorChange = (value) => {
    setBio(value)
  }

  const onFilesChange = (files) => {
    setFiles(files)
  }

  const onDrop = (files) => {
    const [uploadedFile] = files
    setImage(uploadedFile)

    const fileReader = new FileReader()
    fileReader.onload = () => {
      setPreviewSrc(fileReader.result)
    }
    fileReader.readAsDataURL(uploadedFile)
    setIsPreviewAvailable(uploadedFile.name.match(/\.(jpeg|jpg|png)$/))
  }

  const updateBorder = (dragState) => {
    if (dragState === 'over') {
      dropRef.current.style.border = '2px solid #000'
    } else if (dragState === 'leave') {
      dropRef.current.style.border = '2px dashed #e9ebeb'
    }
  }

  const submitHandler = (e) => {
    e.preventDefault()

    const formData = new FormData()

    if (name) formData.append('name', name)
    if (email) formData.append('email', email)
    if (bio) formData.append('bio', bio)
    if (titles) formData.append('titles', JSON.stringify(titles))
    if (areasOfExpertise)
      formData.append('areasOfExpertise', JSON.stringify(areasOfExpertise))
    if (areasOfInterest)
      formData.append('areasOfInterest', JSON.stringify(areasOfInterest))
    if (password) formData.append('password', password)
    if (image) formData.append('image', image)

    dispatch(updateUser(formData, userId))
  }

  return (
    <AdminPageContainer>
      <div className='list-btns'>
        <Link to='/admin/users'>
          <Button className='back-btn'>
            <i className='fas fa-arrow-left' /> Back To User List
          </Button>
        </Link>
      </div>
      <div style={{ marginTop: '60px' }}>
        <h2>Edit User Info</h2>
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error}</Message>
        ) : (
          <Fragment>
            <Form>
              <Row>
                <Col>
                  <Form.Group controlId='name'>
                    <Form.Label className='form-label'>
                      Name: <span className='asterisc'>*</span>
                    </Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='Enter name'
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId='email'>
                    <Form.Label className='form-label'>
                      Email: <span className='asterisc'>*</span>
                    </Form.Label>
                    <Form.Control
                      type='email'
                      placeholder='Enter email'
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Row>

              {/* Start of Titles */}
              <Row className='add-title-group'>
                <Col>
                  <Button onClick={displayTitleInput} className='add-btn'>
                    <i className='fas fa-plus'></i>
                    <span style={{ marginLeft: '5px' }}>Add Title</span>
                  </Button>
                </Col>
                <Col>
                  {titleInpIsOpen && (
                    <div className='item-input-container'>
                      <input
                        type='text'
                        placeholder='Enter Title'
                        value={titleInput}
                        onChange={(e) => setTitleInput(e.target.value)}
                        className='item-input'
                      />
                      <button onClick={addTitle} className='item-add-btn'>
                        Add
                      </button>
                    </div>
                  )}
                </Col>
              </Row>

              <Row className='items-list'>
                <Col>
                  {titles &&
                    titles.map((title, key) => (
                      <Button className='item-btn' key={key}>
                        {title.titleName}{' '}
                        <i
                          className='fas fa-times'
                          onClick={() =>
                            setTitles(
                              titles && titles.filter((item, id) => id !== key)
                            )
                          }
                          style={{ marginLeft: '5px' }}
                        ></i>{' '}
                      </Button>
                    ))}
                </Col>
              </Row>
              {/* End of Titles */}
              {/* Start of Expertise */}
              <Row>
                <Col>
                  <Button onClick={displayExpInput} className='add-btn'>
                    <i className='fas fa-plus'></i>
                    <span style={{ marginLeft: '5px' }}>Add Expertise</span>
                  </Button>
                </Col>
                <Col>
                  {expInpIsOpen && (
                    <div className='item-input-container'>
                      <input
                        type='text'
                        placeholder='Enter Expertise'
                        value={expInput}
                        onChange={(e) => setExpInput(e.target.value)}
                        className='item-input'
                      />
                      <button onClick={addAreaOfExp} className='item-add-btn'>
                        Add
                      </button>
                    </div>
                  )}
                </Col>
              </Row>

              <Row className='items-list'>
                <Col>
                  {areasOfExpertise &&
                    areasOfExpertise.map((exp, key) => (
                      <Button className='item-btn' key={key}>
                        {exp.expertiseName}{' '}
                        <i
                          className='fas fa-times'
                          onClick={() =>
                            setAreasOfExpertise(
                              areasOfExpertise &&
                                areasOfExpertise.filter(
                                  (item, id) => id !== key
                                )
                            )
                          }
                          style={{ marginLeft: '5px' }}
                        ></i>{' '}
                      </Button>
                    ))}
                </Col>
              </Row>
              {/* End of Expertise */}
              {/* Start of Interest */}
              <Row>
                <Col>
                  <Button onClick={displayIntInput} className='add-btn'>
                    <i className='fas fa-plus'></i>
                    <span style={{ marginLeft: '5px' }}>Add Interest</span>
                  </Button>
                </Col>
                <Col>
                  {intInpIsOpen && (
                    <div className='item-input-container'>
                      <input
                        type='text'
                        placeholder='Enter Interest'
                        value={intInput}
                        onChange={(e) => setIntInput(e.target.value)}
                        className='item-input'
                      />
                      <button onClick={addAreaOfInt} className='item-add-btn'>
                        Add
                      </button>
                    </div>
                  )}
                </Col>
              </Row>

              <Row className='items-list'>
                <Col>
                  {areasOfInterest &&
                    areasOfInterest.map((int, key) => (
                      <Button className='item-btn' key={key}>
                        {int.interestName}{' '}
                        <i
                          className='fas fa-times'
                          onClick={() =>
                            setAreasOfInterest(
                              areasOfInterest &&
                                areasOfInterest.filter((item, id) => id !== key)
                            )
                          }
                          style={{ marginLeft: '5px' }}
                        ></i>{' '}
                      </Button>
                    ))}
                </Col>
              </Row>
              {/* End of Interest */}

              {/* Start of Editor */}
              <Row>
                <Col>
                  <QuillUpdate
                    placeholder='Start typing ...'
                    onEditorChange={onEditorChange}
                    onFilesChange={onFilesChange}
                    editorHtml={bio}
                  />
                </Col>
              </Row>
              {/* End of Editor */}

              {/* Start of Pass section */}
              <div
                style={{
                  border: '2px solid red',
                  borderRadius: '5px',
                  margin: '25px 0',
                  padding: '10px 20px',
                  backgroundColor: 'rgba(255, 0, 0, 0.2)',
                }}
              >
                <Row>
                  <Col>
                    <h6 style={{ color: 'red' }}>
                      You may use this section to change your password.
                    </h6>
                  </Col>
                </Row>
                <Row>
                  <Col lg={8}>
                    <Form.Group controlId='password'>
                      <Form.Label className='form-label'>Password:</Form.Label>
                      <Form.Control
                        type='password'
                        placeholder='Enter Password'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col></Col>
                </Row>
              </div>

              {/* End of Pass section */}
            </Form>

            {/* Start of Image Upload */}
            <div className='upload-section'>
              <Dropzone
                onDrop={onDrop}
                onDragEnter={() => updateBorder('over')}
                onDragLeave={() => updateBorder('leave')}
              >
                {({ getRootProps, getInputProps }) => (
                  <div
                    {...getRootProps({ className: 'drop-zone' })}
                    ref={dropRef}
                  >
                    <input {...getInputProps()} />
                    <p>Drag and drop a file OR click here to select a file</p>
                    <p>
                      (Image dimensions should be 200*230 or a ratio of this)
                    </p>

                    {image && (
                      <div>
                        <strong>Selected file:</strong> {image.name}
                      </div>
                    )}
                  </div>
                )}
              </Dropzone>
              {/* {user.image ? (
                <img src={user.image} alt='Preview' className='preview-image' />
              ) : previewSrc ? (
                isPreviewAvailable ? (
                  <div className='image-preview'>
                    <img
                      src={previewSrc}
                      alt='Preview'
                      className='preview-image'
                    />
                  </div>
                ) : (
                  <div className='preview-message'>
                    <p>No preview available for this image</p>
                  </div>
                )
              ) : (
                <div className='preview-message'>
                  <p>Image Preview</p>
                </div>
              )} */}
              {previewSrc ? (
                isPreviewAvailable ? (
                  <div className='image-preview'>
                    <img
                      src={previewSrc}
                      alt='Preview'
                      className='preview-image'
                    />
                  </div>
                ) : (
                  <div className='preview-message'>
                    <p>No preview available for this image</p>
                  </div>
                )
              ) : (
                <div className='preview-message'>
                  <p>Image Preview</p>
                </div>
              )}
            </div>
            {/* End of Image Upload */}

            <Button
              type='button'
              variant='primary'
              style={{
                color: '#fff',
                backgroundColor: '#14213d',
                marginTop: '20px',
                width: '100%',
              }}
              onClick={submitHandler}
            >
              Update
            </Button>
          </Fragment>
        )}
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
      </div>
    </AdminPageContainer>
  )
}

export default UserEdit

import React from 'react'
import { Link } from 'react-router-dom'

const BlogItem = ({ blogItem }) => {
  return (
    <div className='blog__item'>
      <div className='blog__item__media'>
        {(blogItem && blogItem.source === '') ||
        (blogItem && blogItem.source === undefined) ? (
          <img
            src={blogItem && blogItem.image && '/' + blogItem.image}
            alt='blog-item'
            className='blog__item__image'
          />
        ) : (
          <iframe
            src={blogItem.source}
            frameBorder='0'
            className='blog__item__iframe'
          ></iframe>
        )}
      </div>
      <div className='blog__item__info'>
        <h4>{blogItem && blogItem.title}</h4>
        <div className='post__authors'>
          <span className='authors__title'>By:</span>
          <div className='authors__list'>
            {blogItem.authors &&
              blogItem.authors.map((author, i) => (
                <span key={i} className='author'>
                  {author.authorName}
                  {blogItem.authors.indexOf(author) ===
                  blogItem.authors.length - 1
                    ? ''
                    : ','}{' '}
                </span>
              ))}
          </div>
        </div>

        <h6 className='blog__posted'>
          <span className='posted__title'>Posted:</span>{' '}
          <span className='posted__value'>
            {blogItem.posted && blogItem.posted.slice(0, 10)}
          </span>
        </h6>

        {blogItem && blogItem.link ? (
          <a href={blogItem && blogItem.link} target='_blank'>
            <button className='blog__item__btn'>
              Read More{' '}
              <span>
                <i className='fas fa-chevron-right' />
              </span>
            </button>
          </a>
        ) : (
          <Link to={`/latest/${blogItem && blogItem._id}`}>
            <button className='blog__item__btn'>
              Read More{' '}
              <span>
                <i className='fas fa-chevron-right' />
              </span>
            </button>
          </Link>
        )}
      </div>
    </div>
  )
}

export default BlogItem

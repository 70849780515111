import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import {
  memberListReducer,
  memberDetailsReducer,
  memberCreateReducer,
  memberUpdateReducer,
  memberDeleteReducer,
} from './reducers/memberReducers'
import {
  messageListReducer,
  messageDetailsReducer,
  messageCreateReducer,
  messageDeleteReducer,
} from './reducers/messageReducers'
import {
  newsListReducer,
  newsDetailsReducer,
  newsCreateReducer,
  newsUpdateReducer,
  newsDeleteReducer,
} from './reducers/newsReducers'
import {
  projectListReducer,
  projectDetailsReducer,
  projectCreateReducer,
  projectUpdateReducer,
  projectDeleteReducer,
  projectFilterReducer,
  projectClearFilterReducer,
} from './reducers/projectReducers'
import {
  publicationListReducer,
  publicationDetailsReducer,
  publicationCreateReducer,
  publicationUpdateReducer,
  publicationDeleteReducer,
  publicationFilterReducer,
  publicationClearFilterReducer,
  publicationFileDownloadReducer,
} from './reducers/publicationReducers'
import {
  userLoginReducer,
  usersListReducer,
  userDetailsReducer,
  userUpdateReducer,
} from './reducers/userReducers'

const reducer = combineReducers({
  userLogin: userLoginReducer,
  usersList: usersListReducer,
  userDetails: userDetailsReducer,
  userUpdate: userUpdateReducer,
  memberList: memberListReducer,
  memberDetails: memberDetailsReducer,
  memberCreate: memberCreateReducer,
  memberUpdate: memberUpdateReducer,
  memberDelete: memberDeleteReducer,
  messageList: messageListReducer,
  messageDetails: messageDetailsReducer,
  messageCreate: messageCreateReducer,
  messageDelete: messageDeleteReducer,
  newsList: newsListReducer,
  newsDetails: newsDetailsReducer,
  newsCreate: newsCreateReducer,
  newsUpdate: newsUpdateReducer,
  newsDelete: newsDeleteReducer,
  projectList: projectListReducer,
  projectDetails: projectDetailsReducer,
  projectCreate: projectCreateReducer,
  projectUpdate: projectUpdateReducer,
  projectDelete: projectDeleteReducer,
  projectFilter: projectFilterReducer,
  projectClearFilter: projectClearFilterReducer,
  publicationList: publicationListReducer,
  publicationDetails: publicationDetailsReducer,
  publicationCreate: publicationCreateReducer,
  publicationUpdate: publicationUpdateReducer,
  publicationDelete: publicationDeleteReducer,
  publicationFilter: publicationFilterReducer,
  publicationClearFilter: publicationClearFilterReducer,
  publicationFileDownload: publicationFileDownloadReducer,
})

const userInfoFromStorage = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo'))
  : null

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
}

const middleware = [thunk]

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
)

export default store

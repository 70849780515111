import React, { Fragment } from 'react'

import landingVideo from './assets/landing.mp4'

const Landing = ({ landing, children }) => {
  return (
    <Fragment>
      <video autoPlay='autoplay' loop='loop' muted id={landing}>
        <source src={landingVideo} type='video/mp4' />
      </video>

      {children}
    </Fragment>
  )
}

export default Landing

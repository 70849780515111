import React, { useEffect, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Fade } from 'react-reveal'
import { listUsers } from '../../actions/userActions'
import { listMembers } from '../../actions/memberActions'
import PageHeader from '../layout/PageHeader'
import Footer from '../layout/Footer'
import DrHarvey from '../members/DrHarvey'
import LabMembers from '../members/LabMembers'

const Members = () => {
  const dispatch = useDispatch()

  const usersList = useSelector((state) => state.usersList)
  const { users } = usersList

  const memberList = useSelector((state) => state.memberList)
  const { members } = memberList

  useEffect(() => {
    dispatch(listUsers())
    dispatch(listMembers())
  }, [dispatch])

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div id='members'>
      {/* Header */}
      <PageHeader header='header header__members'>
        <Fade left>
          <h2 className='page__header__title'>Who We Are</h2>
        </Fade>
      </PageHeader>

      {/* Dr. Harvey */}
      <DrHarvey user={users && users[0]} />
      {/* Members */}
      <LabMembers members={members} />

      {/* Footer */}
      <Footer />
    </div>
  )
}

export default Members

import React, { useEffect } from 'react'
import jwt from 'jwt-decode'
import { Link } from 'react-router-dom'
import { Table, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/layout/Message'
import Loader from '../components/layout/Loader'
import AdminPageContainer from '../components/layout/AdminPageContainer'
import { listMessages, deleteMessage } from '../actions/messageActions'
import { logout } from '../actions/userActions'

const MessagesList = ({ history }) => {
  const dispatch = useDispatch()

  const messageList = useSelector((state) => state.messageList)
  const { loading, error, messages } = messageList

  const messageDelete = useSelector((state) => state.messageDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = messageDelete

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (userInfo) {
      const token =
        JSON.parse(localStorage.getItem('userInfo')) &&
        JSON.parse(localStorage.getItem('userInfo'))['token']

      if (jwt(token).exp < Date.now() / 1000) {
        dispatch(logout())
        
      } else {
        dispatch(listMessages())
      }
    } else {
      history.push('/admin')
    }
    
  }, [dispatch, history, userInfo, successDelete])

  const deleteHandler = (id) => {
    if (window.confirm('Are you sure?')) {
      dispatch(deleteMessage(id))
    }
  }

  return (
    <AdminPageContainer>
      <div className='list-btns'>
        <h2>List Of Messages</h2>
      </div>
      <div>
        {/* {loadingDelete && <Loader />} */}
        {errorDelete && <Message variant='danger'>{errorDelete}</Message>}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error}</Message>
        ) : (
          <Table
            bordered
            responsive
            className='table-sm'
            variant='light'
            style={{ color: '#495057' }}
          >
            <thead>
              <tr>
                {/* <th className='text-center'>#</th> */}
                <th className='text-center'>Name</th>
                <th className='text-center'>Email</th>
                <th className='text-center'>Subject</th>
                <th></th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              {messages && messages.length > 0 ? (
                messages.map((message) => (
                  <tr key={message._id}>
                    {/* <td className='icon'>
                      {messages && messages.indexOf(message) + 1}
                    </td> */}

                    <td>{message.name}</td>
                    <td>{message.email}</td>
                    <td>{message.subject}</td>

                    <td className='icon'>
                      <Link to={`/admin/messages/${message._id}`}>
                        <i className='fas fa-eye view-icon'></i>
                      </Link>
                    </td>
                    <td className='icon'>
                      <i
                        className='fas fa-trash-alt delete-icon'
                        onClick={() => deleteHandler(message._id)}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>No Messages</td>
                </tr>
              )}
            </tbody>
          </Table>
        )}
      </div>
    </AdminPageContainer>
  )
}

export default MessagesList

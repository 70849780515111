import axios from 'axios'
import {
  PROJECT_LIST_REQUEST,
  PROJECT_LIST_SUCCESS,
  PROJECT_LIST_FAIL,
  PROJECT_DETAILS_REQUEST,
  PROJECT_DETAILS_SUCCESS,
  PROJECT_DETAILS_FAIL,
  PROJECT_CREATE_REQUEST,
  PROJECT_CREATE_SUCCESS,
  PROJECT_CREATE_FAIL,
  PROJECT_UPDATE_REQUEST,
  PROJECT_UPDATE_SUCCESS,
  PROJECT_UPDATE_FAIL,
  PROJECT_DELETE_REQUEST,
  PROJECT_DELETE_SUCCESS,
  PROJECT_DELETE_FAIL,
  PROJECT_FILTER_REQUEST,
  PROJECT_FILTER_SUCCESS,
  PROJECT_FILTER_FAIL,
  PROJECT_CLEAR_FILTER_REQUEST,
  PROJECT_CLEAR_FILTER_SUCCESS,
  PROJECT_CLEAR_FILTER_FAIL,
} from '../constants/projectConstants'

export const listProjects = () => async (dispatch) => {
  try {
    dispatch({ type: PROJECT_LIST_REQUEST })

    const { data } = await axios.get('/api/projects')

    dispatch({
      type: PROJECT_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PROJECT_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const listProjectDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: PROJECT_DETAILS_REQUEST })

    const { data } = await axios.get(`/api/projects/${id}`)

    dispatch({
      type: PROJECT_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PROJECT_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const createProject = (formData) => async (dispatch, getState) => {
  try {
    dispatch({ type: PROJECT_CREATE_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${userInfo && userInfo.token}`,
      },
    }

    const { data } = await axios.post('/api/projects', formData, config)

    dispatch({
      type: PROJECT_CREATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PROJECT_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateProject = (formData, id) => async (dispatch, getState) => {
  try {
    dispatch({ type: PROJECT_UPDATE_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${userInfo && userInfo.token}`,
      },
    }

    const { data } = await axios.put(`/api/projects/${id}`, formData, config)

    dispatch({
      type: PROJECT_UPDATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PROJECT_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deleteProject = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PROJECT_DELETE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo && userInfo.token}`,
      },
    }

    await axios.delete(`/api/projects/${id}`, config)

    dispatch({
      type: PROJECT_DELETE_SUCCESS,
    })
  } catch (error) {
    dispatch({
      type: PROJECT_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const filterProjects = (text) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PROJECT_FILTER_REQUEST,
    })

    const {
      projectList: { projects },
    } = getState()

    const filteredProjects = projects.filter((project) => {
      const regex = new RegExp(`${text}`, 'gi')
      return project.title.match(regex) || project.body.match(regex)
    })

    dispatch({
      type: PROJECT_FILTER_SUCCESS,
      payload: filteredProjects,
    })
  } catch (error) {
    dispatch({
      type: PROJECT_FILTER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const clearFilterProjects = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: PROJECT_CLEAR_FILTER_REQUEST,
    })

    dispatch({
      type: PROJECT_CLEAR_FILTER_SUCCESS,
      payload: null,
    })
  } catch (error) {
    dispatch({
      type: PROJECT_CLEAR_FILTER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

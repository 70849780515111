import React, { useLayoutEffect } from 'react'
import { Link } from 'react-scroll'
import { Fade } from 'react-reveal'
import Landing from '../layout/Landing'
import Banner from '../layout/Banner'
import Cards from '../cards/Cards'
import Contact from '../contact/Contact'
import missionBg from '../../styling/assets/mission.jpg'

const Home = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div id='home'>
      {/* Landing */}
      <Landing landing='landing'>
        <Banner
          title='welcome to'
          subtitle='The leadership and learning for sustainability lab'
          banner='banner'
        >
          <Link
            activeClass='active'
            to='home__challenge'
            spy={true}
            smooth={true}
            duration={1000}
            offset={-100}
            className='landing-btn first'
          >
            The Challenge
          </Link>

          <Link
            activeClass='active'
            to='home__mission'
            spy={true}
            smooth={true}
            duration={1000}
            offset={-80}
            className='landing-btn second'
          >
            Our Mission
          </Link>
        </Banner>
      </Landing>
      {/* The Challenge */}
      <div id='home__challenge'>
        <Fade bottom>
          <h2 className='section__title'>The Challenge that Motivates Us</h2>
        </Fade>
        <Fade bottom>
          <p className='reg__paragraph'>
            Climate change and sustainable development are two of the defining
            challenges of our time. However, our schools and academic
            institutions can do much more to support meaningful action to
            address these challenges. This calls for new tools, policies and
            approaches that can help us work together more effectively. The
            Leadership and Learning for Sustainability Lab is dedicated to
            contributing to these goals.
          </p>
        </Fade>
      </div>
      {/* Our Mission */}
      <div id='home__mission'>
        <div id='home__mission__content'>
          <Fade bottom>
            <h2 className='section__title'>Our Mission</h2>
          </Fade>
          <Fade bottom>
            <p className='reg__paragraph'>
              Inspired by the work of Donella Meadows, the Leadership and
              Learning for Sustainability Lab studies the “leverage points” for
              a transition towards collaborative, transdisciplinary models of
              research and practice. In doing so, we aim to identify how system
              structures, goals, and mindsets impact research and action in our
              schools, higher education institutions, and sites of non-formal
              learning. We believe that facilitated processes of learning and
              collaboration are keys to impactful and socially-just responses to
              global crises like climate change and sustainability.
              Participatory and co-creative approaches rest at the core of this
              mission.
            </p>
          </Fade>
        </div>
        <div id='home__mission__bg'>
          <Fade right>
            <img src={missionBg} alt='mission background' />
          </Fade>
        </div>
      </div>
      <div className='divider'></div>
      {/* Latest Blog Posts */}
      <div id='home__latest'>
        <h2 className='section__title'>Latest In The Lab</h2>
        <Cards />
        <h3 className='latest__link'>
          <a href='/latest'>
            Discover More Media
            <span>
              <i className='fas fa-chevron-right' />
            </span>
          </a>
        </h3>
      </div>
      {/* Contact Form */}
      <div id='home__contact'>
        <Contact />
      </div>

      <div id='home__footer'>
        <p>
          Developed By:
          <a href='mailto:a.s.arabshahi@gmail.com'>a.s.arabshahi@gmail.com</a>
        </p>
      </div>
    </div>
  )
}

export default Home

import React, { useState, useEffect, useRef } from 'react'
import jwt from 'jwt-decode'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Button, Row, Col } from 'react-bootstrap'
import QuillUpdate from '../components/editor/QuillUpdate'
import Message from '../components/layout/Message'
import Loader from '../components/layout/Loader'
import AdminPageContainer from '../components/layout/AdminPageContainer'
import {
  listPublicationDetails,
  updatePublication,
} from '../actions/publicationActions'
import { PUBLICATION_UPDATE_RESET } from '../constants/publicationConstants'
import { logout } from '../actions/userActions'

const PublicationEdit = ({ match, history }) => {
  const publicationId = match.params.id

  const [authorInpIsOpen, setAuthorInpIsOpen] = useState(false)
  const [authorInpForm, setAuthorInpForm] = useState(false)

  const [type, setType] = useState('')
  const [otherType, setOtherType] = useState('')
  const [title, setTitle] = useState('')
  const [authors, setAuthors] = useState([])
  const [name, setName] = useState('')
  const [published, setPublished] = useState('')
  const [journal, setJournal] = useState('')
  const [book, setBook] = useState('')
  const [publisher, setPublisher] = useState('')
  const [link, setLink] = useState('')
  const [DOI, setDOI] = useState('')
  const [abstract, setAbstract] = useState('')
  const [files, setFiles] = useState([])

  const [file, setFile] = useState('')
  const dropRef = useRef()

  const dispatch = useDispatch()

  const publicationDetails = useSelector((state) => state.publicationDetails)
  const { publication } = publicationDetails

  const publicationUpdate = useSelector((state) => state.publicationUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = publicationUpdate

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (userInfo) {
      const token =
        JSON.parse(localStorage.getItem('userInfo')) &&
        JSON.parse(localStorage.getItem('userInfo'))['token']

      if (jwt(token).exp < Date.now() / 1000) {
        dispatch(logout())
      } else {
        if (successUpdate) {
          dispatch({ type: PUBLICATION_UPDATE_RESET })

          history.push('/admin/publications')
        } else {
          if (!publication || publication._id !== publicationId) {
            dispatch(listPublicationDetails(publicationId))
          } else {
            // setType(publication.type)
            setTitle(publication.title)
            setAuthors(publication.authors)
            setPublished(publication.published)
            setJournal(publication.journal)
            setBook(publication.book)
            setPublisher(publication.publisher)
            setLink(publication.link)
            setDOI(publication.DOI)
            setAbstract(publication.body)
            if (
              publication.type === 'Peer Reviewed Journal Article' ||
              publication.type === 'Edited Journal Special Issue' ||
              publication.type === 'Book Chapter'
            )
              setType(publication.type)
            if (
              publication.type !== 'Peer Reviewed Journal Article' &&
              publication.type !== 'Edited Journal Special Issue' &&
              publication.type !== 'Book Chapter'
            ) {
              setType('Others')
              setOtherType(publication.type)
            }
          }
        }
      }
    } else {
      history.push('/admin')
    }
  }, [dispatch, history, userInfo, publication, publicationId, successUpdate])

  const displayNameInput = (e) => {
    e.preventDefault()
    setAuthorInpIsOpen(!authorInpIsOpen)
    setAuthorInpForm(true)
  }

  const addAuthor = (e) => {
    e.preventDefault()
    if (name) {
      setAuthors(
        authors.concat([
          {
            authorName: name,
          },
        ])
      )
      setName('')
    }
  }

  const minOffset = 0
  const maxOffset = 60
  const thisYear = new Date().getFullYear()

  let options = []
  for (let i = minOffset; i <= maxOffset; i++) {
    const year = thisYear - i
    options.push(
      <option key={i} value={year}>
        {year}
      </option>
    )
  }

  const onEditorChange = (value) => {
    setAbstract(value)
  }

  const onFilesChange = (files) => {
    setFiles(files)
  }

  const submitHandler = async (e) => {
    e.preventDefault()

    const formData = new FormData()

    if (file) formData.append('file', file)
    if (title) formData.append('title', title)
    if (type) formData.append('type', otherType ? otherType : type)
    if (abstract) formData.append('body', abstract)
    if (authors) formData.append('authors', JSON.stringify(authors))
    if (published) formData.append('published', published)
    if (journal) formData.append('journal', journal)
    if (book) formData.append('book', book)
    if (publisher) formData.append('publisher', publisher)
    if (link) formData.append('link', link)
    if (DOI) formData.append('DOI', DOI)

    dispatch(updatePublication(formData, publicationId))
  }

  return (
    <AdminPageContainer>
      <div className='list-btns' style={{ marginBottom: '50px' }}>
        <Link to='/admin/publications'>
          <Button className='back-btn'>
            <i className='fas fa-arrow-left' /> Back To Publications
          </Button>
        </Link>
        <Link to='/admin/publication/create'>
          <Button
            style={{
              left: '0',
              right: 'auto',
              border: 'none',
              marginLeft: '190px',
            }}
            className='list-btn'
          >
            <i className='fas fa-plus'></i> Create Publication
          </Button>
        </Link>
      </div>
      <h2>Update Publication</h2>
      <Form>
        {/* Start of Title */}
        <Row>
          <Col>
            <Form.Group controlId='title'>
              <Form.Label>
                Title: <span className='asterisc'>*</span>{' '}
              </Form.Label>
              <Form.Control
                type='text'
                placeholder='Title'
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>
        {/* Start of Title */}

        {/* Start of Publication Year */}
        <Row>
          <Col>
            <Form.Group controlId='published'>
              <Form.Label>
                Published At: <span className='asterisc'>*</span>{' '}
              </Form.Label>
              <Form.Control
                type='text'
                as='select'
                placeholder='Enter publication year'
                value={published}
                onChange={(e) => setPublished(e.target.value)}
              >
                <option>Select year</option>
                {options}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        {/* End of Publication Year */}

        {/* Start of Author Input */}
        <Row className='add-author-group'>
          <Col>
            <Button onClick={displayNameInput} className='add-btn'>
              <i className='fas fa-plus'></i>
              <span style={{ marginLeft: '5px' }}>Add Author</span>
            </Button>
          </Col>
          <Col>
            {authorInpIsOpen && (
              <div className='item-input-container'>
                <input
                  type='text'
                  placeholder='Enter Author'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className='item-input'
                />
                <button onClick={addAuthor} className='item-add-btn'>
                  Add
                </button>
              </div>
            )}
          </Col>
        </Row>
        {/* End of Author Input */}

        {/* Start of Author(s) display */}
        <Row className='items-list'>
          <Col>
            {authors &&
              authors.map((author, key) => (
                <Button className='item-btn' key={key}>
                  {author.authorName}{' '}
                  <i
                    className='fas fa-times'
                    onClick={() =>
                      setAuthors(
                        authors && authors.filter((item, id) => id !== key)
                      )
                    }
                    style={{ marginLeft: '5px' }}
                  ></i>{' '}
                </Button>
              ))}
          </Col>
        </Row>
        {/* End of Author(s) display */}

        {/* Start of Publication Type */}
        <Row>
          <Col>
            <Form.Group controlId='type'>
              <Form.Label>
                Publication Type: <span className='asterisc'>*</span>{' '}
              </Form.Label>
              <Form.Control
                as='select'
                placeholder='Enter type'
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                <option>Select a Type</option>
                <option>Peer Reviewed Journal Article</option>
                <option>Edited Journal Special Issue</option>
                <option>Book Chapter</option>
                <option>Others</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId='otherType'>
              <Form.Label>Specify Others:</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter Type'
                value={otherType}
                disabled={otherType !== '' ? false : true}
                onChange={(e) => setOtherType(e.target.value)}
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>
        {/* End of Publication Type */}

        {/* Start of Journal, Book & Publisher */}
        {type === 'Book Chapter' ? (
          <Row>
            <Col>
              <Form.Group controlId='book'>
                <Form.Label>Book:</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Book (Optional)'
                  value={book}
                  onChange={(e) => setBook(e.target.value)}
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId='publisher'>
                <Form.Label>Publisher:</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Publisher (Optional)'
                  value={publisher}
                  onChange={(e) => setPublisher(e.target.value)}
                ></Form.Control>
              </Form.Group>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col>
              <Form.Group controlId='journal'>
                <Form.Label>Journal:</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Journal (Optional)'
                  value={journal}
                  onChange={(e) => setJournal(e.target.value)}
                ></Form.Control>
              </Form.Group>
            </Col>
          </Row>
        )}
        {/* End of Journal, Book & Publisher */}

        {/* Start of Publication Link */}
        <Row>
          <Col>
            <Form.Group controlId='link'>
              <Form.Label>Link to Publication:</Form.Label>
              <Form.Control
                type='text'
                placeholder='Link (Optional)'
                value={link}
                onChange={(e) => setLink(e.target.value)}
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>
        {/* End of Publication Link */}

        {/* Start of Publication DOI */}
        <Row>
          <Col>
            <Form.Group controlId='DOI'>
              <Form.Label>Publication DOI:</Form.Label>
              <Form.Control
                type='text'
                placeholder='DOI (Optional)'
                value={DOI}
                onChange={(e) => setDOI(e.target.value)}
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>
        {/* End of Publication DOI */}

        {/* Start of Editor */}
        <Row>
          <Col>
            <QuillUpdate
              placeholder='Insert Publication Abstract ...'
              onEditorChange={onEditorChange}
              onFilesChange={onFilesChange}
              editorHtml={abstract}
            />
          </Col>
        </Row>
        {/* End of Editor */}
      </Form>

      {/* Start of File Upload */}
      {/* <Dropzone
          onDrop={onDrop}
          onDragEnter={() => updateBorder('over')}
          onDragLeave={() => updateBorder('leave')}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps({ className: 'drop-zone' })} ref={dropRef}>
              <input {...getInputProps()} />
              <p>Drag and drop a file OR click here to select a file</p>
              {file && (
                <div>
                  <strong>Selected file:</strong> {file.name}
                </div>
              )}
            </div>
          )}
        </Dropzone> */}
      {/* End of File Upload */}

      <Button
        type='button'
        style={{
          color: '#fff',
          backgroundColor: '#14213d',
          marginTop: '20px',
        }}
        onClick={submitHandler}
      >
        Update
      </Button>
      {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
      {loadingUpdate && <Loader />}
    </AdminPageContainer>
  )
}

export default PublicationEdit

import axios from 'axios'
import {
  PUBLICATION_LIST_REQUEST,
  PUBLICATION_LIST_SUCCESS,
  PUBLICATION_LIST_FAIL,
  PUBLICATION_DETAILS_REQUEST,
  PUBLICATION_DETAILS_SUCCESS,
  PUBLICATION_DETAILS_FAIL,
  PUBLICATION_CREATE_REQUEST,
  PUBLICATION_CREATE_SUCCESS,
  PUBLICATION_CREATE_FAIL,
  PUBLICATION_UPDATE_REQUEST,
  PUBLICATION_UPDATE_SUCCESS,
  PUBLICATION_UPDATE_FAIL,
  PUBLICATION_DELETE_REQUEST,
  PUBLICATION_DELETE_SUCCESS,
  PUBLICATION_DELETE_FAIL,
  PUBLICATION_FILTER_REQUEST,
  PUBLICATION_FILTER_SUCCESS,
  PUBLICATION_FILTER_FAIL,
  PUBLICATION_CLEAR_FILTER_REQUEST,
  PUBLICATION_CLEAR_FILTER_SUCCESS,
  PUBLICATION_CLEAR_FILTER_FAIL,
  PUBLICATION_FILE_DOWNLOAD_REQUEST,
} from '../constants/publicationConstants'

export const listPublications = () => async (dispatch) => {
  try {
    dispatch({ type: PUBLICATION_LIST_REQUEST })

    const { data } = await axios.get('/api/publications')

    dispatch({
      type: PUBLICATION_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PUBLICATION_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const listPublicationDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: PUBLICATION_DETAILS_REQUEST })

    const { data } = await axios.get(`/api/publications/${id}`)

    dispatch({
      type: PUBLICATION_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PUBLICATION_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const createPublication = (formData) => async (dispatch, getState) => {
  try {
    dispatch({ type: PUBLICATION_CREATE_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${userInfo && userInfo.token}`,
      },
    }

    const { data } = await axios.post('/api/publications', formData, config)

    dispatch({
      type: PUBLICATION_CREATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PUBLICATION_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updatePublication =
  (formData, id) => async (dispatch, getState) => {
    try {
      dispatch({ type: PUBLICATION_UPDATE_REQUEST })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${userInfo && userInfo.token}`,
        },
      }

      const { data } = await axios.put(
        `/api/publications/${id}`,
        formData,
        config
      )

      dispatch({
        type: PUBLICATION_UPDATE_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: PUBLICATION_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const deletePublication = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PUBLICATION_DELETE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo && userInfo.token}`,
      },
    }

    await axios.delete(`/api/publications/${id}`, config)

    dispatch({
      type: PUBLICATION_DELETE_SUCCESS,
    })
  } catch (error) {
    dispatch({
      type: PUBLICATION_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const filterPublications = (text) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PUBLICATION_FILTER_REQUEST,
    })

    const {
      publicationList: { publications },
    } = getState()

    const filteredPublications = publications.filter((publication) => {
      const regex = new RegExp(`${text}`, 'gi')
      return publication.title.match(regex) || publication.body.match(regex)
    })

    dispatch({
      type: PUBLICATION_FILTER_SUCCESS,
      payload: filteredPublications,
    })
  } catch (error) {
    dispatch({
      type: PUBLICATION_FILTER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const clearFilterPublications = () => async (dispatch) => {
  try {
    dispatch({
      type: PUBLICATION_CLEAR_FILTER_REQUEST,
    })

    dispatch({
      type: PUBLICATION_CLEAR_FILTER_SUCCESS,
      payload: null,
    })
  } catch (error) {
    dispatch({
      type: PUBLICATION_CLEAR_FILTER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

import React, { useEffect, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Fade } from 'react-reveal'
import { listNews } from '../../actions/newsActions'
import PageHeader from '../layout/PageHeader'
import BlogCards from '../news/BlogCards'
import Footer from '../layout/Footer'

const Blogs = () => {
  const dispatch = useDispatch()

  const newsList = useSelector((state) => state.newsList)
  const { loading, error, news } = newsList

  useEffect(() => {
    dispatch(listNews())
  }, [dispatch])

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div id='blogs'>
      {/* Header */}
      <PageHeader header='header header__blogs'>
        <Fade left>
          <h2 className='page__header__title'>News & Events</h2>{' '}
        </Fade>
      </PageHeader>

      {/* Blog Posts */}
      <h2 className='section__title centered'>Latest In The Lab</h2>
      <BlogCards news={news} loading={loading} error={error} />

      {/* Footer */}
      <Footer />
    </div>
  )
}

export default Blogs

import React from 'react'

const ProjectNavItem = ({ project, active }) => {
  const { title, startYear, endYear, _id } = project
  return (
    <div className={`project__nav__item ${_id === active ? 'active' : ''}`}>
      <a href={`/project/${_id}`}>{title}</a>{' '}
      <span>
        ({startYear && startYear}
        {startYear !== endYear && '-'}
        {startYear !== endYear && endYear})
      </span>
    </div>
  )
}

export default ProjectNavItem

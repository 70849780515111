import React from 'react'
import ContactForm from './ContactForm'

const Contact = () => {
  return (
    <div className='contact__container'>
      <div className='contact__container__info'>
        <h3>Prospective Students</h3>
        <p style={{ fontSize: '1.1rem', margin: '30px 0 20px 0' }}>
          We always welcome new students. If you are interested to join us,
          contact us at:
        </p>
        <div className='align__left'>
          <h5 className='contact-by'>
            <i className='fas fa-envelope' />
            <a
              href='mailto:blane.harvey@mcgill.ca'
              style={{
                paddingLeft: '10px',
                color: '#14213d',
                textDecoration: 'underline',
              }}
            >
              blane.harvey@mcgill.ca
            </a>
          </h5>

          <h5 className='contact-by'>
            <i className='fas fa-university' />
            <span style={{ paddingLeft: '10px' }}>
              <a
                href='https://www.mcgill.ca/dise/'
                target='_blank'
                style={{ textDecoration: 'underline', color: '#14213d' }}
              >
                Department of Integrated Studies in Education,
              </a>{' '}
            </span>
          </h5>
          <h5 className='contact-by' style={{ marginLeft: '30px' }}>
            McGill University Faculty of Education.
          </h5>
          <h5 className='contact-by'>
            <i className='fas fa-map-marker-alt'></i>
            <span style={{ paddingLeft: '10px' }}>
              Room 365 Education Building, 514-398-4527 ext. 00506
            </span>
          </h5>

          <h6 className='contact-by' style={{ marginTop: '40px' }}>
            Social Media:
          </h6>

          <div className='socials'>
            <a
              href='https://twitter.com/BlaneHarvey'
              target='_blank'
              style={{ marginRight: '5px' }}
            >
              <i
                className='fab fa-twitter-square'
                style={{ color: '#14213d', fontSize: '2.4rem' }}
              />
            </a>
            <a
              href='https://www.researchgate.net/profile/Blane_Harvey'
              target='_blank'
            >
              <i
                className='fab fa-researchgate'
                style={{ color: '#14213d', fontSize: '2.4rem' }}
              />
            </a>
          </div>
        </div>
      </div>
      <div className='contact__container__form'>
        <h3>Get In Touch With Us</h3>
        <ContactForm />
      </div>
    </div>
  )
}

export default Contact

import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../layout/Message'
import Loader from '../layout/Loader'
import ProjectNavItem from './ProjectNavItem'
import { listProjects } from '../../actions/projectActions'

const ProjectNav = ({ active }) => {
  const dispatch = useDispatch()

  const projectList = useSelector((state) => state.projectList)
  const { loading, error, projects } = projectList

  useEffect(() => {
    dispatch(listProjects())
  }, [dispatch])

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          {projects &&
            projects.map((project, id) => (
              <ProjectNavItem key={id} project={project} active={active} />
            ))}
        </>
      )}
    </Fragment>
  )
}

export default ProjectNav

import axios from 'axios'
import {
  MEMBER_LIST_REQUEST,
  MEMBER_LIST_SUCCESS,
  MEMBER_LIST_FAIL,
  MEMBER_DETAILS_REQUEST,
  MEMBER_DETAILS_SUCCESS,
  MEMBER_DETAILS_FAIL,
  MEMBER_CREATE_REQUEST,
  MEMBER_CREATE_SUCCESS,
  MEMBER_CREATE_FAIL,
  MEMBER_UPDATE_REQUEST,
  MEMBER_UPDATE_SUCCESS,
  MEMBER_UPDATE_FAIL,
  MEMBER_DELETE_REQUEST,
  MEMBER_DELETE_SUCCESS,
  MEMBER_DELETE_FAIL,
} from '../constants/memberConstants'

export const listMembers = () => async (dispatch) => {
  try {
    dispatch({ type: MEMBER_LIST_REQUEST })

    const { data } = await axios.get('/api/members')

    dispatch({
      type: MEMBER_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: MEMBER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const listMemberDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: MEMBER_DETAILS_REQUEST })

    const { data } = await axios.get(`/api/members/${id}`)

    dispatch({
      type: MEMBER_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: MEMBER_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const createMember = (formData) => async (dispatch, getState) => {
  try {
    dispatch({ type: MEMBER_CREATE_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${userInfo && userInfo.token}`,
      },
    }

    const { data } = await axios.post('/api/members', formData, config)

    dispatch({
      type: MEMBER_CREATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: MEMBER_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateMember = (formData, id) => async (dispatch, getState) => {
  try {
    dispatch({ type: MEMBER_UPDATE_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${userInfo && userInfo.token}`,
      },
    }

    const { data } = await axios.put(`/api/members/${id}`, formData, config)

    dispatch({
      type: MEMBER_UPDATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: MEMBER_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deleteMember = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: MEMBER_DELETE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo && userInfo.token}`,
      },
    }

    await axios.delete(`/api/members/${id}`, config)

    dispatch({
      type: MEMBER_DELETE_SUCCESS,
    })
  } catch (error) {
    dispatch({
      type: MEMBER_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

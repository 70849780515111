import React from 'react'
import MemberItem from './MemberItem'

const LabMembers = ({ members }) => {
  const currentMems =
    members &&
    members.filter(
      (mem) => mem.status && mem.status.toLowerCase() === 'current'
    )

  const pastMems =
    members &&
    members.filter((mem) => mem.status && mem.status.toLowerCase() === 'past')

  const partners =
    members &&
    members.filter(
      (mem) => mem.status && mem.status.toLowerCase() === 'partner'
    )

  return (
    <div className='members'>
      {/* Current Members */}
      {currentMems && currentMems.length > 0 && (
        <h2 className='section__title'>Current Members</h2>
      )}
      <div className='members__current'>
        {currentMems &&
          currentMems.length > 0 &&
          currentMems.map((mem, id) => <MemberItem key={id} member={mem} />)}
      </div>

      {/* Past Members */}
      {pastMems && pastMems.length > 0 && (
        <h2 className='section__title'>Past Members</h2>
      )}
      <div className='members__past'>
        {pastMems &&
          pastMems.length > 0 &&
          pastMems.map((mem, id) => <MemberItem key={id} member={mem} />)}
      </div>

      {/* Partners */}
      {partners && partners.length > 0 && (
        <h2 className='section__title'>Partners</h2>
      )}
      <div className='partners'>
        {partners &&
          partners.length > 0 &&
          partners.map((mem, id) => <MemberItem key={id} member={mem} />)}
      </div>
    </div>
  )
}

export default LabMembers

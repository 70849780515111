import {
  PUBLICATION_LIST_REQUEST,
  PUBLICATION_LIST_SUCCESS,
  PUBLICATION_LIST_FAIL,
  PUBLICATION_DETAILS_REQUEST,
  PUBLICATION_DETAILS_SUCCESS,
  PUBLICATION_DETAILS_FAIL,
  PUBLICATION_CREATE_REQUEST,
  PUBLICATION_CREATE_SUCCESS,
  PUBLICATION_CREATE_FAIL,
  PUBLICATION_CREATE_RESET,
  PUBLICATION_UPDATE_REQUEST,
  PUBLICATION_UPDATE_SUCCESS,
  PUBLICATION_UPDATE_FAIL,
  PUBLICATION_UPDATE_RESET,
  PUBLICATION_DELETE_REQUEST,
  PUBLICATION_DELETE_SUCCESS,
  PUBLICATION_DELETE_FAIL,
  PUBLICATION_FILTER_REQUEST,
  PUBLICATION_FILTER_SUCCESS,
  PUBLICATION_FILTER_FAIL,
  PUBLICATION_FILTER_RESET,
  PUBLICATION_CLEAR_FILTER_REQUEST,
  PUBLICATION_CLEAR_FILTER_SUCCESS,
  PUBLICATION_CLEAR_FILTER_FAIL,
  PUBLICATION_FILE_DOWNLOAD_REQUEST,
  PUBLICATION_FILE_DOWNLOAD_SUCCESS,
  PUBLICATION_FILE_DOWNLOAD_FAIL,
} from '../constants/publicationConstants'

export const publicationListReducer = (
  state = { publications: [] },
  action
) => {
  switch (action.type) {
    case PUBLICATION_LIST_REQUEST:
      return { loading: true, publications: [] }
    case PUBLICATION_LIST_SUCCESS:
      return { loading: false, publications: action.payload }
    case PUBLICATION_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const publicationDetailsReducer = (
  state = { publication: {} },
  action
) => {
  switch (action.type) {
    case PUBLICATION_DETAILS_REQUEST:
      return { loading: true, ...state }
    case PUBLICATION_DETAILS_SUCCESS:
      return { loading: false, publication: action.payload }
    case PUBLICATION_DETAILS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const publicationCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case PUBLICATION_CREATE_REQUEST:
      return { loading: true }
    case PUBLICATION_CREATE_SUCCESS:
      return { loading: false, success: true, publication: action.payload }
    case PUBLICATION_CREATE_FAIL:
      return { loading: false, error: action.payload }
    case PUBLICATION_CREATE_RESET:
      return {}
    default:
      return state
  }
}

export const publicationUpdateReducer = (
  state = { publication: {} },
  action
) => {
  switch (action.type) {
    case PUBLICATION_UPDATE_REQUEST:
      return { loading: true }
    case PUBLICATION_UPDATE_SUCCESS:
      return { loading: false, success: true, publication: action.payload }
    case PUBLICATION_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case PUBLICATION_UPDATE_RESET:
      return { publication: {} }
    default:
      return state
  }
}

export const publicationDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case PUBLICATION_DELETE_REQUEST:
      return { loading: true }
    case PUBLICATION_DELETE_SUCCESS:
      return { loading: false, success: true }
    case PUBLICATION_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const publicationFilterReducer = (
  state = { filteredPublications: null },
  action
) => {
  switch (action.type) {
    case PUBLICATION_FILTER_REQUEST:
      return { loading: true, filteredPublications: null }
    case PUBLICATION_FILTER_SUCCESS:
      return {
        loading: false,
        success: true,
        filteredPublications: action.payload,
      }
    case PUBLICATION_FILTER_FAIL:
      return { loading: false, error: action.payload }
    case PUBLICATION_FILTER_RESET:
      return { filteredPublications: null }
    default:
      return state
  }
}

export const publicationClearFilterReducer = (state = {}, action) => {
  switch (action.type) {
    case PUBLICATION_CLEAR_FILTER_REQUEST:
      return { loading: true }
    case PUBLICATION_CLEAR_FILTER_SUCCESS:
      return { loading: false, success: true, filteredPublications: null }
    case PUBLICATION_CLEAR_FILTER_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const publicationFileDownloadReducer = (
  state = { publicationFile: {} },
  action
) => {
  switch (action.type) {
    case PUBLICATION_FILE_DOWNLOAD_REQUEST:
      return { loading: true, ...state }
    case PUBLICATION_FILE_DOWNLOAD_SUCCESS:
      return { loading: false, publicationFile: action.payload }
    case PUBLICATION_FILE_DOWNLOAD_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

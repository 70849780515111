import React, { useState, useEffect, useRef } from 'react'
import jwt from 'jwt-decode'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Button, Row, Col } from 'react-bootstrap'
import Dropzone from 'react-dropzone'
import QuillUpdate from '../components/editor/QuillUpdate'
import Message from '../components/layout/Message'
import Loader from '../components/layout/Loader'
import AdminPageContainer from '../components/layout/AdminPageContainer'
import { listNewsDetails, updateNewsItem } from '../actions/newsActions'
import { NEWS_UPDATE_RESET } from '../constants/newsConstants'
import { logout } from '../actions/userActions'

const NewsEdit = ({ match, history }) => {
  const newsId = match.params.id

  const [authorInpIsOpen, setAuthorInpIsOpen] = useState(false)
  const [authorInpForm, setAuthorInpForm] = useState(false)

  const [title, setTitle] = useState('')
  const [authors, setAuthors] = useState([])
  const [name, setName] = useState('')
  const [link, setLink] = useState('')
  const [source, setSource] = useState('')
  const [body, setBody] = useState('')
  const [posted, setPosted] = useState(Date.now())
  const [image, setImage] = useState('')
  const [files, setFiles] = useState([])

  const [previewSrc, setPreviewSrc] = useState('')
  const [isPreviewAvailable, setIsPreviewAvailable] = useState(false)
  const dropRef = useRef()

  const dispatch = useDispatch()

  const newsDetails = useSelector((state) => state.newsDetails)
  const { newsItem } = newsDetails

  const newsUpdate = useSelector((state) => state.newsUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = newsUpdate

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (userInfo) {
      const token =
        JSON.parse(localStorage.getItem('userInfo')) &&
        JSON.parse(localStorage.getItem('userInfo'))['token']

      if (jwt(token).exp < Date.now() / 1000) {
        dispatch(logout())
      } else {
        if (successUpdate) {
          dispatch({ type: NEWS_UPDATE_RESET })
          history.push('/admin/news')
        } else {
          if (!newsItem || newsItem._id !== newsId) {
            dispatch(listNewsDetails(newsId))
          } else {
            setTitle(newsItem.title)
            setAuthors(newsItem.authors)
            setLink(newsItem.link)
            setBody(newsItem.body)
            setPosted(newsItem.posted)
            setSource(
              newsItem.source &&
                `https://www.youtube.com/watch?v=${newsItem.source.slice(30)}`
            )
          }
        }
      }
    } else {
      history.push('/admin')
    }
  }, [dispatch, history, userInfo, newsItem, newsId, successUpdate])

  const displayNameInput = (e) => {
    e.preventDefault()
    setAuthorInpIsOpen(!authorInpIsOpen)
    setAuthorInpForm(true)
  }

  const addAuthor = (e) => {
    e.preventDefault()
    if (name) {
      setAuthors(
        authors.concat([
          {
            authorName: name,
          },
        ])
      )
      setName('')
    }
  }

  console.log(authors)

  const onEditorChange = (value) => {
    setBody(value)
  }

  const onFilesChange = (files) => {
    setFiles(files)
  }

  const onDrop = (files) => {
    const [uploadedFile] = files
    setImage(uploadedFile)

    const fileReader = new FileReader()
    fileReader.onload = () => {
      setPreviewSrc(fileReader.result)
    }
    fileReader.readAsDataURL(uploadedFile)
    setIsPreviewAvailable(uploadedFile.name.match(/\.(jpeg|jpg|png)$/))
  }

  const updateBorder = (dragState) => {
    if (dragState === 'over') {
      dropRef.current.style.border = '2px solid #000'
    } else if (dragState === 'leave') {
      dropRef.current.style.border = '2px dashed #e9ebeb'
    }
  }

  const submitHandler = (e) => {
    e.preventDefault()

    const formData = new FormData()

    if (image) formData.append('image', image)
    if (title) formData.append('name', title)
    if (body) formData.append('body', body)
    if (authors) formData.append('authors', JSON.stringify(authors))
    if (link) formData.append('link', link)
    if (source)
      formData.append(
        'source',
        source ? `https://www.youtube.com/embed/${source.slice(32)}` : ''
      )
    if (posted) formData.append('posted', posted)

    dispatch(updateNewsItem(formData, newsId))
  }

  return (
    <AdminPageContainer>
      <div className='list-btns' style={{ marginBottom: '50px' }}>
        <Link to='/admin/news'>
          <Button className='back-btn'>
            <i className='fas fa-arrow-left' /> Back To Posts
          </Button>
        </Link>
        <Link to='/admin/newsitem/create'>
          <Button
            style={{
              left: '0',
              right: 'auto',
              border: 'none',
              marginLeft: '140px',
            }}
            className='list-btn'
          >
            <i className='fas fa-plus'></i> Create Post
          </Button>
        </Link>
      </div>
      <h2>Update Post</h2>
      <Form>
        {/* Start of Post Title */}
        <Row>
          <Col>
            <Form.Group controlId='title'>
              <Form.Label>
                Title: <span className='asterisc'>*</span>{' '}
              </Form.Label>
              <Form.Control
                type='text'
                placeholder='Title'
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>
        {/* End of Post Title */}

        {/* Start of Author Input */}
        <Row className='add-title-group'>
          <Col>
            <Button onClick={displayNameInput} className='add-btn'>
              <i className='fas fa-plus'></i>
              <span style={{ marginLeft: '5px' }}>Add Author</span>
            </Button>
          </Col>
          <Col>
            {authorInpIsOpen && (
              <div className='item-input-container'>
                <input
                  type='text'
                  placeholder='Enter Title'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className='item-input'
                />
                <button onClick={addAuthor} className='item-add-btn'>
                  Add
                </button>
              </div>
            )}
          </Col>
        </Row>
        {/* End of Author Input */}

        {/* Start of Author(s) display */}
        <Row className='items-list'>
          <Col>
            {authors &&
              authors.map((author, key) => (
                <Button className='item-btn' key={key}>
                  {author.authorName}{' '}
                  <i
                    className='fas fa-times'
                    onClick={() =>
                      setAuthors(
                        authors && authors.filter((item, id) => id !== key)
                      )
                    }
                    style={{ marginLeft: '5px' }}
                  ></i>{' '}
                </Button>
              ))}
          </Col>
        </Row>
        {/* End of Author(s) display */}

        {/* Start of Post Link */}
        <Row>
          <Col lg={7}>
            <Form.Group controlId='link'>
              <Form.Label>Link to News:</Form.Label>
              <Form.Control
                type='text'
                placeholder='Link (Optional)'
                value={link}
                onChange={(e) => setLink(e.target.value)}
              ></Form.Control>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId='posted'>
              <Form.Label className='form-label'>Posted:</Form.Label>
              <Form.Control
                type='date'
                placeholder='Posted'
                value={posted}
                onChange={(e) => setPosted(e.target.value)}
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>
        {/* End of Post Link */}

        {/* Start of YouTube Source */}
        <Row className='add-url-group'>
          <Col>
            <Form.Group controlId='source'>
              <Form.Label>YouTube URL:</Form.Label>
              <Form.Control
                type='text'
                placeholder='YouTube URL (Optional)'
                value={source}
                onChange={(e) => setSource(e.target.value)}
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>
        {/* End of YouTube Source */}
        {/* Start of Editor */}
        <Row>
          <Col>
            <QuillUpdate
              placeholder='Insert Post Body ...'
              onEditorChange={onEditorChange}
              onFilesChange={onFilesChange}
              editorHtml={body}
            />
          </Col>
        </Row>
        {/* End of Editor */}
      </Form>
      {/* Start of Image Upload */}
      <div className='upload-section'>
        <Dropzone
          onDrop={onDrop}
          onDragEnter={() => updateBorder('over')}
          onDragLeave={() => updateBorder('leave')}
        >
          {({ getRootProps, getInputProps }) => (
            <div
              {...getRootProps({ className: 'news-drop-zone' })}
              ref={dropRef}
            >
              <input {...getInputProps()} />
              <p>Drag and drop an image OR click here to select an image</p>
              <p>(Image dimensions should be 260*200 or a ratio of this)</p>

              {image && (
                <div>
                  <strong>Selected file:</strong> {image.name}
                </div>
              )}
            </div>
          )}
        </Dropzone>
        {previewSrc ? (
          isPreviewAvailable ? (
            <div className='news-image-preview'>
              <img
                className='preview-news-image'
                src={previewSrc}
                alt='Preview'
              />
            </div>
          ) : (
            <div className='preview-message'>
              <p>No preview available for this image</p>
            </div>
          )
        ) : (
          <div className='preview-message'>
            <p>Image Preview</p>
          </div>
        )}
      </div>
      {/* End of Image Upload */}
      <Button
        type='button'
        variant='primary'
        style={{
          color: '#fff',
          backgroundColor: '#14213d',
          marginTop: '20px',
        }}
        onClick={submitHandler}
      >
        Update
      </Button>
      {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
      {loadingUpdate && <Loader />}
    </AdminPageContainer>
  )
}

export default NewsEdit

import React, { useEffect, useLayoutEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { listProjectDetails } from '../../actions/projectActions'
import { listMembers } from '../../actions/memberActions'
import DefaultBg from '../../styling/assets/Headers/project.jpg'
import avatar from '../../styling/assets/avatar.png'
import Loader from '../layout/Loader'
import Message from '../layout/Message'
import SocialMedia from '../layout/SocialMedia'
import ProjectNav from '../research/ProjectNav'
import Footer from '../layout/Footer'

const Project = ({ match }) => {
  const dispatch = useDispatch()
  const projectId = match.params.id

  const projectDetails = useSelector((state) => state.projectDetails)
  const { loading, error, project } = projectDetails

  const memberList = useSelector((state) => state.memberList)
  const { members } = memberList

  useEffect(() => {
    dispatch(listProjectDetails(projectId))
    dispatch(listMembers())

    window.onscroll = function () {
      scrollFunction()
    }

    const scrollFunction = () => {
      if (
        document.body.scrollTop > 200 ||
        document.documentElement.scrollTop > 200
      ) {
        document.getElementById('social__media__container') &&
          document
            .getElementById('social__media__container')
            .classList.add('apply__offset')
      } else {
        document.getElementById('social__media__container') &&
          document
            .getElementById('social__media__container')
            .classList.remove('apply__offset')
      }
    }
  }, [dispatch, projectId, window])

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const investigateCoApps =
    project &&
    project.coApps &&
    project.coApps.map((co) => {
      return members && members.filter((member) => member.name === co.coAppName)
    })

  return (
    <div className='project__screen'>
      {/* Project Screen Header */}
      <img
        // src={newsItem && newsItem.image ? '/' + newsItem.image : DefaultBg}
        src={DefaultBg}
        alt='project-header'
        className='project__screen__header'
      />

      {/* Project Screen Body */}
      <div className='back__link'>
        <button>
          <Link to='/research' className='back__link__btn'>
            Back To Projects
          </Link>
        </button>
      </div>

      <div className='project__screen__container'>
        <div>
          <div className='share__actions' id='social__media__container'>
            <SocialMedia title={project && project.title} image={DefaultBg} />
          </div>
        </div>

        <div className='project__main'>
          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant='danger'>{error}</Message>
          ) : (
            <div className='project__body'>
              <div className='project__timeline'>
                <h6>
                  {project && project.startYear} - {project && project.endYear}
                </h6>
              </div>
              <h1 className='project__title'>
                {project && project.title}{' '}
                {project.status === 'Current' ? (
                  <span>(In Progress)</span>
                ) : (
                  <span>(Completed)</span>
                )}
              </h1>

              {project && project.coApps && project.coApps.length > 0 && (
                <h5 className='project__applicants'>
                  <span
                    style={{
                      fontSize: '0.9rem',
                      fontWeight: '600',
                    }}
                  >
                    Co-applicant with:
                  </span>
                  <div className='coapps'>
                    {project &&
                      project.coApps &&
                      project.coApps.map((co, idx) => (
                        <div key={idx} className='coapp'>
                          <div className='coapp__image'>
                            <img
                              src={
                                investigateCoApps && investigateCoApps[idx][0]
                                  ? '/' + investigateCoApps[idx][0].image
                                  : avatar
                              }
                              // alt='author'
                            />
                          </div>
                          <span>{co.coAppName}</span>
                        </div>
                      ))}
                  </div>
                </h5>
              )}

              {project && project.fundedBy && project.fundedBy.length > 0 && (
                <h5 className='project__funds'>
                  <span
                    style={{
                      fontSize: '0.9rem',
                      fontWeight: '600',
                    }}
                  >
                    Funded By:
                  </span>
                  <ul>
                    {project &&
                      project.fundedBy &&
                      project.fundedBy.map((fundingInst, idx) => (
                        <li key={idx}>{fundingInst.fundingInstName}</li>
                      ))}
                  </ul>
                </h5>
              )}

              <div
                dangerouslySetInnerHTML={{ __html: project && project.body }}
                className='project__content'
              />
            </div>
          )}
        </div>
        <div className='project__navbar'>
          <ProjectNav active={match.params.id} />
        </div>
      </div>

      {/* Footer */}
      <Footer />
    </div>
  )
}

export default Project

import React, { useState } from 'react'
import Modal from '../layout/PubModal'

const PublicationItem = ({ publication }) => {
  const [status, setStatus] = useState(false)

  return (
    <>
      {status && (
        <Modal closeModal={() => setStatus(false)}>
          <div>
            {publication && publication.link ? (
              <a href={publication && publication.link} target='_blank'>
                <h4 className='modal__publication__title'>
                  {publication && publication.title}{' '}
                  <span>({publication && publication.published})</span>{' '}
                </h4>
              </a>
            ) : (
              <h4 className='modal__publication__title'>
                {publication && publication.title}{' '}
                <span>({publication && publication.published})</span>{' '}
              </h4>
            )}
            <button className='modal__publication__type'>
              {publication && publication.type}
            </button>

            <div className='modal__publication__authors'>
              <span className='authors__title'>Authors:</span>
              <div className='authors__list'>
                {publication &&
                  publication.authors &&
                  publication.authors.map((author, i) => (
                    <span key={i} className='author'>
                      {author.authorName}
                      {i === publication.authors &&
                      publication.authors.length - 1
                        ? ','
                        : ''}
                    </span>
                  ))}
              </div>
            </div>

            <h6 style={{ marginTop: '20px', fontWeight: '900' }}>Abstract:</h6>

            <div
              dangerouslySetInnerHTML={{
                __html: publication && publication.body,
              }}
              className='modal__publication__body'
            />
          </div>
        </Modal>
      )}
      <div className='publication__item' onClick={() => setStatus(true)}>
        <div className='publication__item__info'>
          {publication && publication.link ? (
            <a href={publication && publication.link} target='_blank'>
              <h5 className='publication__title'>
                {publication && publication.title}{' '}
                <span>({publication && publication.published})</span>{' '}
              </h5>
            </a>
          ) : (
            <h5 className='publication__title'>
              {publication && publication.title}{' '}
              <span>({publication && publication.published})</span>{' '}
            </h5>
          )}

          <div className='publication__authors'>
            <span className='authors__title'>Authors:</span>
            <div className='authors__list'>
              {publication &&
                publication.authors &&
                publication.authors.map((author, i) => (
                  <span key={i} className='author'>
                    {author.authorName}
                    {i === publication.authors && publication.authors.length - 1
                      ? ','
                      : ''}
                  </span>
                ))}
            </div>
          </div>

          <button onClick={() => setStatus(true)} className='publication__btn'>
            Read More
          </button>
        </div>
      </div>
    </>
  )
}

export default PublicationItem

import React, { useEffect } from 'react'
import jwt from 'jwt-decode'
import { Link } from 'react-router-dom'
import { Table, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/layout/Message'
import Loader from '../components/layout/Loader'
import AdminPageContainer from '../components/layout/AdminPageContainer'
import { listUsers, logout } from '../actions/userActions'

const UserList = ({ history }) => {
  const dispatch = useDispatch()

  const usersList = useSelector((state) => state.usersList)
  const { loading, error, users } = usersList

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (userInfo) {
      const token =
        JSON.parse(localStorage.getItem('userInfo')) &&
        JSON.parse(localStorage.getItem('userInfo'))['token']

      if (jwt(token).exp < Date.now() / 1000) {
        dispatch(logout())
      } else {
        dispatch(listUsers())
      }
    } else {
      history.push('/admin')
    }
  }, [dispatch, history, userInfo])

  return (
    <AdminPageContainer>
      <div className='list-child'>
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error}</Message>
        ) : (
          <Table
            bordered
            responsive
            className='table-sm'
            variant='light'
            style={{ color: '#495057' }}
          >
            <thead>
              <tr>
                <th className='text-center'>#</th>
                <th className='text-center'>Name</th>
                <th className='text-center'>Email</th>
                <th className='text-center medium-width'>Titles</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {users &&
                users.map((user) => (
                  <tr key={user._id}>
                    <td style={{ width: '15px' }}>1</td>
                    <td style={{ width: '120px' }}>{user.name}</td>
                    <td style={{ width: '120px' }}>{user.email}</td>
                    <td style={{ width: '200px' }}>
                      {user.titles &&
                        user.titles.map((title) => (
                          <li
                            key={user.titles.indexOf(title)}
                            style={{
                              listStyle: 'square outside none',
                              padding: '8px 0',
                            }}
                          >
                            {title.titleName}
                          </li>
                        ))}
                    </td>

                    <td className='icon'>
                      <Link to={`/admin/user/${user._id}/edit`}>
                        <i className='fas fa-edit edit-icon'></i>
                      </Link>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        )}
      </div>
    </AdminPageContainer>
  )
}

export default UserList

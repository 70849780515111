import React from 'react'
import { Route, Redirect } from 'react-router-dom'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = localStorage.getItem('userInfo')

  return (
    <Route
      exact
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: '/admin', state: { from: props.location } }}
          />
        )
      }
    />
  )
}

export default PrivateRoute

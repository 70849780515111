import React from 'react'

const SocialMedia = ({ title, image }) => {
  const facebookLink = document.querySelector('.facebook__link')
  const twitterLink = document.querySelector('.twitter__link')
  const linkedinLink = document.querySelector('.linkedin__link')

  const postUrl = encodeURI(
    'https://learn2sustain.ca/latest/619fc30f9bcc06170107171c'
  )
  // const postUrl = encodeURI(document.location.href)
  const postTitle = encodeURI(title)
  const postImage = encodeURI(image)
  // const facebookAppId = 490875188871275

  const handleShare = (e) => {
    facebookLink.setAttribute(
      'href',
      `https://www.facebook.com/sharer.php?u=${postUrl}`
    )
    twitterLink.setAttribute(
      'href',
      `https://twitter.com/share?url=${postUrl}&text=${postTitle}`
    )
    linkedinLink.setAttribute(
      'href',
      `https://www.linkedin.com/shareArticle?url=${postUrl}&title=${postTitle}`
    )
  }
  return (
    <>
      {/* Social Share Links:
    Facebook:
    https://www.facebook.com/sharer.php?u=[post-url]

    Twitter:
    https://twitter.com/share?url=[post-url]&text=[post-title]&via=[via]&hashtags=[hashtags]

    LinkedIn:
    https://www.linkedin.com/shareArticle?url=[post-url]&title=[post-title]

     */}
      <a href='' className='facebook__link'>
        <i onClick={handleShare} className='fab fa-facebook' />
      </a>
      <a href='' className='twitter__link'>
        <i onClick={handleShare} className='fab fa-twitter' />
      </a>
      <a href='' className='linkedin__link'>
        <i onClick={handleShare} className='fab fa-linkedin' />
      </a>
    </>
  )
}

export default SocialMedia

import React from 'react'
import { Fade } from 'react-reveal'

const Banner = ({ title, subtitle, banner, bannerlinks, children }) => {
  return (
    <div id={banner}>
      <Fade bottom>
        <h2>{title}</h2>
      </Fade>
      <Fade bottom>
        <h1>{subtitle}</h1>
      </Fade>

      <Fade bottom>
        <div id={bannerlinks}>{children}</div>
      </Fade>
    </div>
  )
}

export default Banner
